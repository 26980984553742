import axios from '@/axios/config'
import { capitalize } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    channels: [],
  },
  mutations: {
    setReservations(state, payload) {
      state.list = payload
    },
    setChannels: (state, channels) => (state.channels = channels),
  },
  getters: {
    channelsItems: state =>
      state.channels.map(channel => ({
        text: capitalize(channel),
        value: channel,
      })),
  },
  actions: {
    async alterReservation(store, { id, ...payload }) {
      try {
        const { data } = await axios.put(`/api/reservations/${id}`, payload)
        return data
      } catch (err) {
        console.error(err)
      }
    },
    async updateReservation({ commit }, { id, ...payload }) {
      commit('updateListingLoading', true, { root: true })
      try {
        const { data } = await axios.put(
          `/api/reservations/${id}/info`,
          payload
        )
        commit('updateListingLoading', false, { root: true })
        return data
      } catch (err) {
        commit('updateListingLoading', false, { root: true })
        console.error(err)
      }
    },
    async createReservation({ commit }, payload) {
      try {
        commit('updateLoading', true, { root: true })
        const { data } = await axios.post(`/api/reservations/`, payload)
        commit('updateLoading', false, { root: true })
        return data
      } catch (err) {
        commit('updateLoading', false, { root: true })
        console.error(err)
      }
      return {}
    },
    async createInternalReservation({ commit }, payload) {
      try {
        commit('updateLoading', true, { root: true })
        const { data } = await axios.post(`/api/reservations/internal`, payload)
        commit('updateLoading', false, { root: true })
        return data
      } catch (err) {
        commit('updateLoading', false, { root: true })
        console.error(err)
      }
      return {}
    },
    async getReservation({ commit }, { id, include_cal, refresh }) {
      const { data } = await axios.get(`/api/reservations/${id}`, {
        params: { include_cal, refresh },
      })
      commit('setCurrentListingCalendar', { data }, { root: true })
      return data
    },
    async getReservations({ commit }, date) {
      try {
        const { data } = await axios.get('/api/reservations', {
          params: {
            date,
          },
        })
        commit('setReservations', data)
      } catch (err) {
        console.log(err)
      }
    },
    async searchReservations(store, { term }) {
      const { data } = await axios.get('/api/conversations/search', {
        params: { term },
      })
      return data
    },
    async addInvoice(store, { reservation_id, ...data }) {
      return axios.post(`/api/reservation/${reservation_id}/add-invoice`, data)
    },
    async removeInvoice(store, { reservation_id, invoice }) {
      return axios.post(
        `/api/reservation/${reservation_id}/remove-invoice`,
        invoice
      )
    },
    async emailInvoice(_, { id, ...data }) {
      return axios.post(`/api/reservations/${id}/email-invoice`, data)
    },
    async confirmAgentReservation(_, payload) {
      return axios.post(`/api/reservations/confirm-agent-reservation`, payload)
    },
    async fetchChannels({ commit }) {
      try {
        const response = await axios.get('/api/reservations/channels')
        const channels = response.data.payload.data.channels
        commit('setChannels', channels)
      } catch (error) {
        console.error('Error fetching channels:', error)
      }
    },
    async fetchVerificationDetails({ _commit }, { reservation_id }) {
      try {
        const response = await axios.get(
          '/api/reservations/verification_details',
          {
            params: { reservation_id },
          }
        )
        return response.data.payload.data.verification_details
      } catch (error) {
        console.error('Error fetching channels:', error)
      }
    },
    async generateBackupDoorCodes({ _commit }, { reservation_id }) {
      try {
        await axios.get('/api/reservations/generate-backup-codes', {
          params: { reservation_id },
        })
      } catch (error) {
        console.error('Error fetching channels:', error)
      }
    },
  },
}
