<template>
  <v-row class="white">
    <v-row class="col-12 pl-8">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          item-key="id"
          :items="itemsFull"
          disable-sort
          hide-default-footer
          disable-pagination
        >
        </v-data-table>
      </v-col>
    </v-row>
    <v-row class="col-12 pl-8">
      <div class="text-body-2 font-weight-semibold black--text mx-4 mt-8">
        Account split
      </div>
      <v-col class="" cols="12" md="6" lg="12">
        <v-row>
          <investment-card
            :loading="false"
            :cols="isMobile ? 6 : isIPad ? 6 : 3"
            height="112"
            :card="cards.owner"
          />
          <investment-card
            :loading="false"
            :cols="isMobile ? 6 : isIPad ? 6 : 3"
            height="112"
            :card="cards.taxes"
          />
          <investment-card
            :loading="false"
            :cols="isMobile ? 6 : isIPad ? 6 : 3"
            height="112"
            :card="cards.operational"
          />
          <investment-card
            :loading="false"
            :cols="isMobile ? 6 : isIPad ? 6 : 3"
            height="112"
            :card="cards.management"
          />
        </v-row>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import FormattersMixin from 'components/mixins/formatters-mixin'
import Common_functions from 'components/mixins/common_functions'
import deviceMixin from 'components/mixins/device-mixin'
import InvestmentCard from 'components/investment-info/investment-card'

export default {
  name: 'ReservationFinances',
  mixins: [FormattersMixin, Common_functions, deviceMixin],
  components: { InvestmentCard },
  props: ['items', 'reservation', 'headers'],
  computed: {
    itemsFull() {
      return this.items
    },
    cards() {
      const owner_profit =
        this.reservation.fare_accommodation -
        this.reservation.management_commission -
        this.reservation.processing_fee
      return {
        owner: {
          label: this.$t('Owner'),
          color: '#dbeafe',
          textColor: '#396646',
          value: owner_profit,
        },
        taxes: {
          label: this.$t('Taxes'),
          color: '#fee2e2',
          textColor: '#603128',
          value: this.reservation.total_taxes,
        },
        management: {
          label: this.$t('Management'),
          color: '#ffedd5',
          textColor: '#746039',
          value: this.reservation.management_commission,
        },
        operational: {
          label: this.$t('Operational'),
          color: '#E7FCDC',
          textColor: '#396646',
          value: this.reservation.cleaning_fee,
        },
      }
    },
  },
  methods: {
    removeInvoiceItem(item) {
      this.$emit('remove-invoice', {
        invoice: item,
      })
    },
  },
}
</script>

<style scoped>
.title-black {
  color: #3e3f59;
}
:deep() .v-data-table-header > tr > th {
  font-weight: 600;
  font-size: 14px !important;
  color: black !important;
}
</style>
