<template>
  <v-tabs v-model="paramsTab2" icons-and-text>
    <v-tab href="#pricings">
      Pricings
      <v-icon>$accounting</v-icon>
    </v-tab>
    <v-tab href="#settings">
      Settings
      <v-icon>$settings</v-icon>
    </v-tab>
    <v-tab href="#los">
      LOS
      <v-icon>$analytics</v-icon>
    </v-tab>
    <v-tab-item id="pricings" class="pl-4 pr-4 main-background">
      <v-container grid-list-md text-center>
        <v-layout wrap class="align-center">
          <v-flex xs12 sm4 md4 lg4>
            <v-text-field
              v-model="searchTerm"
              class="mt-1 mb-3"
              flat
              label="Search..."
              append-icon="search"
              hide-details
              solo-inverted
            />
          </v-flex>
          <v-flex row wrap xs12 sm4 md4 lg4 mt-2 mb-2 class="pb-3">
            <v-btn
              :class="showActivepricings ? 'cyan-icon' : 'grey--text'"
              class="text-caption ml-4"
              large
              text
              outlined
              @click="showActivepricings = true"
            >
              Active
            </v-btn>
            <v-btn
              :class="showActivepricings ? 'grey--text' : 'cyan-icon'"
              class="text-caption ml-4 mr-4"
              large
              text
              outlined
              @click="showActivepricings = false"
            >
              Disabled
            </v-btn>
          </v-flex>
          <v-flex>
            <v-checkbox
              v-model="onlyMulti"
              hide-details
              label="Only multi"
            ></v-checkbox>
            <v-checkbox
              v-model="onlyListed"
              hide-details
              label="Only listed"
            ></v-checkbox>
          </v-flex>
        </v-layout>
        <v-layout wrap class="justify-center center-text">
          <v-pagination
            v-if="pricingPagniation"
            v-model="page"
            :length="
              Math.ceil(
                pricingPagniation.properties_count / pricingPagniation.per_page
              )
            "
            prev-icon="chevron_left"
            next-icon="chevron_right"
            class="mt-3 mb-3"
          ></v-pagination>
        </v-layout>
        <v-layout wrap>
          <v-flex v-for="pricing in filteredPricings" :key="pricing.id" xs12>
            <pricing-card :pricing="pricing"></pricing-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-tab-item>
    <v-tab-item id="settings" class="pl-4 pr-4 main-background">
      <pricing-settings></pricing-settings>
    </v-tab-item>
    <v-tab-item id="los" class="pl-4 pr-4 main-background">
      <los-settings-table />
    </v-tab-item>
    <v-tab-item id="stats" class="pl-4 pr-4 main-background">
      <v-layout>
        <pricing-general-stats></pricing-general-stats>
      </v-layout>
    </v-tab-item>
    <v-tab-item id="area" class="pl-4 pr-4 main-background">
      <v-layout>
        <cities :cities="cities" />
      </v-layout>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import PricingCard from './pricing-card.vue'
import orderBy from 'lodash/orderBy'
import PricingSettings from 'components/pricing-settings.vue'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import PricingGeneralStats from 'components/pricing-general-stats.vue'
import Cities from 'components/cities.vue'
import { mapState } from 'vuex'
import LosSettingsTable from 'components/los-settings/los-settings-table.vue'

export default {
  mixins: [CommonFunctions, PermissionsMixin],
  data() {
    return {
      page: 1,
      searchTerm: '',
      paramsTab2: null,
      showActivepricings: true,
      onlyMulti: false,
      onlyListed: true,
    }
  },
  created() {
    this.$store.dispatch('getAllPricing', {
      page: this.page,
      activated: true,
      only_listed: true,
    })
    this.$store.dispatch('getPricingStats', { from: new Date() })
  },
  computed: {
    ...mapState(['cities']),
    pricingPagniation() {
      return this.$store.state.pricingPagniation
    },
    filteredPricings: {
      get() {
        let res = []
        if (this.$store.state.pricings) {
          let active = this.showActivepricings
          this.$store.state.pricings.forEach(pricing => {
            const title = pricing.title || ''
            const address = pricing.address || ''
            if (
              (title.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                address
                  .toLowerCase()
                  .includes(this.searchTerm.toLowerCase())) &&
              pricing.activated === active
            ) {
              res.push(pricing)
            }
          })
          res = orderBy(res, 'yearly_stats.health', 'desc')
        }
        return res
      },
    },
  },
  watch: {
    page() {
      this.$store.dispatch('getAllPricing', {
        page: this.page,
        activated: this.showActivepricings,
        search_term: this.searchTerm,
        only_multi: this.onlyMulti,
        only_listed: this.onlyListed,
      })
    },
    searchTerm: {
      handler() {
        this.page = 1
        this.$store.dispatch('getAllPricing', {
          page: this.page,
          activated: this.showActivepricings,
          search_term: this.searchTerm,
          only_multi: this.onlyMulti,
          only_listed: this.onlyListed,
        })
      },
      deep: true,
    },
    onlyMulti: {
      handler() {
        this.page = 1
        this.$store.dispatch('getAllPricing', {
          page: this.page,
          activated: this.showActivepricings,
          search_term: this.searchTerm,
          only_multi: this.onlyMulti,
          only_listed: this.onlyListed,
        })
      },
      deep: true,
    },
    showActivepricings(val) {
      this.$store.dispatch('getAllPricing', {
        page: this.page,
        activated: val,
        search_term: this.searchTerm,
        only_multi: this.onlyMulti,
        only_listed: this.onlyListed,
      })
    },
  },
  components: {
    PricingCard,
    PricingSettings,
    PricingGeneralStats,
    Cities,
    LosSettingsTable,
  },
}
</script>
