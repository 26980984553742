<template>
  <div class="ma-6">
    <v-alert type="info" text dense class="mb-4 font-weight-medium text-body-2">
      Please note that Guest Book is currently in beta, and we are actively
      working to improve it.
    </v-alert>
    <v-card
      outlined
      class="d-flex justify-space-between py-4 px-8 rounded-lg pre-checkin-container"
    >
      <pre-checkin-stat
        class="flex-1"
        label="Repeat Guest Rate"
        :value="toPercent(stats.repeat_guest_rate * 100, 1)"
        color="var(--v-info-base)"
      />
      <pre-checkin-stat
        class="flex-1"
        label="Avg Length of Stay"
        :value="stats.avg_stay_length"
        color="var(--v-darkpurple-base)"
      />
      <pre-checkin-stat
        class="flex-1"
        label="Avg ADR"
        :value="dollarFormatter(stats.adr)"
        color="var(--v-secondary600-base)"
      />
      <pre-checkin-stat
        class="flex-1"
        label="Avg Booking window"
        :value="stats.avg_booking_window"
        color="var(--v-dark500-base)"
      />
      <pre-checkin-stat
        class="flex-1"
        label="Avg Review Score"
        :value="stats.avg_reviews_score"
        color="var(--v-yellow-base)"
      />
      <pre-checkin-stat
        class="flex-1"
        label="Upsell/Cross-sell Success Rate"
        :value="toPercent(stats.upsell_success_rate * 100, 1)"
        color="var(--v-success600-base)"
      />
    </v-card>
    <div class="me-2 my-2 d-flex justify-space-between">
      <div class="d-flex align-center">
        <guest-book-filters @apply-filters="fetchGuests(1, $event)" />
      </div>
      <div class="d-flex align-center">
        <v-btn
          class="text-capitalize"
          color="secondary"
          small
          elevation="0"
          @click="exportToExcel"
        >
          Export To Excel
        </v-btn>
        <v-text-field
          v-model="search"
          outlined
          clearable
          dense
          prepend-inner-icon="mdi-magnify"
          label="Search"
          hide-details
          class="scaled-filter"
          @click:clear="clearSearch"
          @keyup="searchDebouncer(1, $event)"
        />
      </div>
    </div>
    <guest-modal
      :key="selectedGuestId"
      :value.sync="showModal"
      :guest-id="selectedGuestId"
    />
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-0"
      :loading="guestBookLoading"
      :options="pagination"
      :server-items-length="pagination.count"
      @click:row="rowClick"
      @update:options="paginationChanged($event)"
    >
      <template #item.full_name="{ item }">
        <div class="d-flex align-center my-2">
          <div class="me-2">
            <v-avatar size="35" color="light400">
              <span class="black--text font-weight-medium">{{
                initialsFromFullName(item.full_name)
              }}</span>
            </v-avatar>
          </div>
          <div>
            <div class="text-body-2 font-weight-medium d-flex align-center">
              {{ item.full_name }}
              <div
                v-for="tag in item.extra_data?.user_tags || []"
                :key="tag"
                class="text-capitalize font-weight-medium py-0 px-2 rounded-pill text-caption black950--text ms-2 d-flex align-center"
                :style="`background-color: ${randomColors(tag)}1A;`"
              >
                <div
                  class="dot me-1"
                  :style="`background-color: ${randomColors(tag)};`"
                />
                {{ tag }}
              </div>
            </div>
            <div class="dark500--text text-caption font-weight-medium">
              {{ item.primary_email }}
            </div>
          </div>
        </div>
      </template>
      <template #item.profile_tags="{ item }">
        <v-chip
          v-for="tag in item.extra_data?.profile_tags"
          :key="tag"
          label
          small
          outlined
          class="me-2"
        >
          {{ tag }}</v-chip
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import PreCheckinStat from 'components/crm/pre-checkin-stat.vue'
import GuestModal from 'components/guests/guest-modal.vue'
import CommonFunctions from 'components/mixins/common_functions'
import FormattersMixin from 'components/mixins/formatters-mixin'
import debounce from 'lodash/debounce'
import GuestBookFilters from 'components/guests/guest-book-filters.vue'

export default {
  name: 'PreCheckIns',
  mixins: [CommonFunctions, FormattersMixin],
  components: {
    PreCheckinStat,
    GuestModal,
    GuestBookFilters,
  },
  data() {
    return {
      searchDebouncer: null,
      search: '',
      showModal: false,
      filterDialog: false,
      selectedGuestId: null,
      pagination: {},
      guestBookLoading: false,
      stats: {},
      headers: [
        {
          text: 'Full Name',
          value: 'full_name',
          sortable: false,
        },
        {
          text: 'Email',
          value: 'primary_email',
          sortable: false,
        },
        {
          text: 'ADR',
          value: 'extra_data.adr',
          align: 'center',
        },
        {
          text: 'Last Review',
          value: 'extra_data.last_res_review_score',
          align: 'center',
        },
        {
          text: 'Club member',
          value: 'extra_data.club_member',
          align: 'center',
        },
        {
          text: 'Last Comm',
          value: 'extra_data.last_message_from_guest',
          align: 'center',
        },
        {
          text: 'Vists Count',
          value: 'extra_data.reservations_count',
          align: 'center',
        },
        {
          text: 'Last Visit',
          value: 'extra_data.last_check_in',
          align: 'center',
        },
        { text: 'Profile Tags', value: 'profile_tags', sortable: false },
      ],
      items: [],
    }
  },
  mounted() {
    this.searchDebouncer = debounce(this.fetchGuests, 500)
    this.fetchGuests()
  },
  methods: {
    async fetchGuests(page = 1, filters = {}) {
      this.guestBookLoading = true
      const { guests, pagination, stats } = await this.$store.dispatch(
        'guests/fetchGuests',
        {
          term: this.search,
          guest_book: true,
          filters,
          page,
        }
      )
      if (page === 1) this.stats = stats
      this.pagination = pagination
      const maxLength = 30
      this.items = guests.map(g => ({
        ...g,
        primary_email:
          g.primary_email?.length > maxLength
            ? g.primary_email.slice(0, maxLength) + '...'
            : g.primary_email,
        extra_data: {
          ...g.extra_data,
          adr: this.toMoney(g.extra_data?.adr),
          last_res_review_score: this.round(
            g.extra_data.last_res_review_score / 2,
            1
          ),
          last_message_from_guest: this.parseDate(
            g.extra_data.last_message_from_guest
          ),
          last_check_in: this.parseDate(g.extra_data.last_check_in),
        },
      }))
      this.guestBookLoading = false
    },
    rowClick(item) {
      this.selectedGuestId = item.id
      this.showModal = true
    },
    paginationChanged({ sortBy, sortDesc, page }) {
      if (this.guestBookLoading) return
      if (!sortBy[0] && !sortDesc[0] && page == this.pagination.page) return
      this.fetchGuests(page, {
        sort_by: sortBy[0],
        sort_order: !sortBy[0] || sortDesc[0] ? 'desc' : 'asc',
      })
    },
    clearSearch() {
      this.search = ''
      this.fetchGuests()
    },
    exportToExcel() {
      this.fetchGuests(1, {
        export: 'excel',
      })
    },
  },
}
</script>

<style scoped>
.pre-checkin-container {
  border-color: none !important;
  background-color: var(--v-light100-base) !important;
}
.dot {
  width: 5;
  height: 5;
  border-radius: 50%;
  display: inline-block;
}
.scaled-filter {
  transform: scale(0.8);
  transform-origin: center;
}
</style>
