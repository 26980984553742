<template>
  <v-container fluid>
    <v-row class="align-center justify-space-between">
      <v-row>
        <v-col cols="2">
          <v-select
            v-model="filters.expense_role"
            :label="$t('Expense type')"
            :items="expenseRolesList"
            outlined
            dense
            clearable
            hide-details
          />
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="filters.search"
            :label="$t('Search')"
            dense
            outlined
            clearable
            hide-details
          />
        </v-col>
      </v-row>

      <v-col cols="12" sm="auto">
        <v-btn small color="primary" @click="showExpenseTypeModal(null)">
          <v-icon>mdi-plus</v-icon>
          {{ $t('Add Expense Type') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-data-table
        :headers="headers"
        :items-per-page="30"
        :items="filteredItems"
        class="elevation-1 w-100 mx-3"
      >
        <template #item.amount="{ item }">
          <span v-if="item.units === 'percentage'">
            {{ `${item.amount}% of ${expenseSettings[item.quantifier] || ''}` }}
          </span>
          <span v-else>
            {{ item.amount }}
          </span>
        </template>
        <template #item.actions="{ item }">
          <v-btn small text fab @click="showExpenseTypeModal(item)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <confirmation-modal
            text="Are you sure you want
              to delete this Expense Type?"
            @action="removeSelctedExpenseType(item.id)"
          >
            <template #activator="{ on }">
              <v-btn small text fab v-on="on">
                <v-icon small>$trash</v-icon>
              </v-btn>
            </template>
          </confirmation-modal>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ConfirmationModal from 'components/modals/confirmation-modal'

export default {
  name: 'ExpenseTypes',
  components: {
    ConfirmationModal,
  },
  computed: {
    ...mapState({
      expenseSettings: state => state.expenseTypes.expenseSettings,
    }),
    expenseRolesList() {
      return ['Business', 'Client']
    },
    categories() {
      return [...new Set(this.items.map(item => item.category))]
    },
    filteredItems() {
      return this.items.filter(item => {
        if (this.filters.search) {
          const search = this.filters.search.toLowerCase()
          if (
            !item.name.toLowerCase().includes(search) &&
            !item.category.toLowerCase().includes(search) &&
            !item.expense_role.toLowerCase().includes(search)
          ) {
            return false
          }
        }

        if (
          this.filters.expense_role &&
          item.expense_role.toLowerCase() !==
            this.filters.expense_role.toLowerCase()
        ) {
          return false
        }

        return true
      })
    },
  },
  data: function () {
    return {
      filters: {
        expense_role: null,
        search: null,
      },
      headers: [
        { text: 'Expense type', value: 'name', sortable: false },
        { text: 'P&L category', value: 'category', sortable: false },
        { text: 'Type', value: 'expense_role', sortable: false },
        { text: 'Amount', value: 'amount', sortable: false },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false },
      ],
      items: [],
      expenseRoles: [
        { name: 'Business', value: 'business' },
        { name: 'Client', value: 'client' },
      ],
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...mapActions('expenseTypes', [
      'fetchAllExpenseTypes',
      'removeExpenseType',
    ]),
    fetchData() {
      this.fetchAllExpenseTypes(this.filters).then(data => {
        this.items = data.items
      })
    },
    showExpenseTypeModal(item = null) {
      const props = {
        categories: this.categories,
        expenseRoles: this.expenseRoles,
        afterSave: this.fetchData,
        expenseType: item,
      }
      this.$store.commit('showModal', {
        name: 'ExpenseTypeModal',
        maxWidth: 400,
        props,
      })
    },
    removeSelctedExpenseType(id) {
      this.removeExpenseType(id).then(() => {
        this.fetchData()
      })
    },
  },
}
</script>
