<template>
  <v-dialog
    v-model="dialog"
    class="pre-made-messages"
    :disabled="disabled"
    max-width="800"
    @click:outside="close"
  >
    <template #activator="{ on }">
      <v-btn
        :small="isMobile"
        :icon="isMobile"
        :disabled="disabled"
        elevation="0"
        v-on="on"
      >
        <v-icon color="info">mdi-message-text</v-icon>
      </v-btn>
    </template>
    <v-card class="pa-4 p-relative">
      <div class="d-flex pa-4">
        <div
          class="flex text-center justify-center font-weight-medium text-subtitle-1"
        >
          Pre-made messages
        </div>
        <v-icon class="close-btn" @click="close">close</v-icon>
      </div>
      <v-row no-gutters class="align-center">
        <v-col cols="12" class="correct-area">
          <div class="d-flex">
            <v-textarea
              v-model="preMessage.body"
              outlined
              dense
              hide-details
              class="flex"
              placeholder="Select message..."
            />
            <span class="d-flex flex-column justify-end">
              <v-btn
                :disabled="!preMessage.body || preMessage.tags.length > 1"
                icon
                text
                @click="chooseMessage(preMessage)"
              >
                <v-icon color="primary">fas fa-paper-plane</v-icon>
              </v-btn>
            </span>
          </div>
          <div v-if="preMessage.tags.length" class="pt-2">
            <tag
              v-for="(tag, i) in preMessage.tags"
              :key="`tag-${i}`"
              class="mr-2"
              size="sm"
              inverted
              color="pink"
            >
              {{ tag }}
              <v-btn icon small @click="removeTag(i)">
                <v-icon color="pink" small>$delete</v-icon>
              </v-btn>
            </tag>
            <div
              v-if="preMessage.tags.length > 1"
              class="error--text text-caption"
            >
              Only one tag is allowed
            </div>
          </div>
          <div class="py-2">
            <v-btn
              class="text-capitalize"
              color="info"
              small
              elevation="0"
              @click="
                preMessage.body += conversation['guest_full_name'].split(' ')[0]
              "
            >
              Insert guest name
            </v-btn>
          </div>
        </v-col>
        <v-col>
          <v-text-field
            v-model="searchTerm"
            label="Search"
            autofocus
            dense
            outlined
            hide-details
            prepend-inner-icon="search"
          />
        </v-col>
        <v-col cols="auto" class="pl-2">
          <v-btn outlined @click="addMsg">
            <v-icon>add</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-btn-toggle v-model="msgType" class="my-2">
        <v-btn small value="all">All</v-btn>
        <v-btn small value="Customer-Support">Customer-Support</v-btn>
        <v-btn small value="Sales">Sales</v-btn>
      </v-btn-toggle>
      <DynamicScroller
        :items="filteredItems"
        :min-item-size="25"
        class="scroller"
      >
        <template #default="{ item: msg, index, active }">
          <DynamicScrollerItem
            :item="msg"
            :active="active"
            :size-dependencies="[msg.body]"
            :data-index="index"
          >
            <div class="pa-2">
              <div
                class="d-flex flex-column flex-sm-row align-center justify-space-between"
              >
                <div>
                  <Tag inverted color="info">{{ msg.field }}</Tag>
                  <div class="pa-1">
                    <span class="font-weight-medium mr-2 text-body-2">
                      {{ msg.title }}
                    </span>
                    <Tag size="sm" inverted color="pink">
                      {{ msg.sales_status }}
                    </Tag>
                    <div class="text-caption break-word">{{ msg.body }}</div>
                    <div>
                      <v-chip
                        v-for="key in msg.keywords"
                        :key="key"
                        label
                        x-small
                        class="mx-1"
                      >
                        #{{ key }}
                      </v-chip>
                    </div>
                  </div>
                </div>
                <div class="d-flex py-2">
                  <v-icon
                    class="mx-3"
                    color="info"
                    small
                    @click="pushMessage(msg)"
                  >
                    fas fa-paper-plane
                  </v-icon>
                  <v-icon class="mx-3" small @click="editMsg(msg)">edit</v-icon>
                  <ConfirmationModal
                    :text="`Are you sure you want to remove ${msg.title} message ?`"
                    @action="deleteMsg(msg.id)"
                  >
                    <template #activator="{ on }">
                      <v-icon small v-on="on">$trash</v-icon>
                    </template>
                  </ConfirmationModal>
                </div>
              </div>
              <v-divider v-show="index < filteredItems.length - 1" />
            </div>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
    </v-card>
  </v-dialog>
</template>
<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import { mapMutations, mapState } from 'vuex'
import ConfirmationModal from 'components/modals/confirmation-modal'
import Tag from 'components/common/tag'
import { isEmpty } from 'lodash'

export default {
  components: { Tag, ConfirmationModal },
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin],
  props: ['afterMessageChosen', 'disabled'],
  data: function () {
    return {
      msgType: 'all',
      dialog: false,
      searchTerm: '',
      newMessage: {},
      preMessage: {
        tags: [],
        body: '',
      },
    }
  },
  mounted() {
    if (isEmpty(this.$store.state.conversationTags)) {
      this.$store.dispatch('getChatConversationTags')
    }
  },
  computed: {
    ...mapState(['preMadeMessages']),
    filteredItems() {
      return this.preMadeMessages.filter(item => {
        const { title, body, sales_status, field, keywords } = item
        const searchTerm = this.searchTerm.toLowerCase()
        const isKeyWordMatch = keywords
          ? keywords.some(k => k.includes(searchTerm))
          : false
        const isSearchMatch =
          (title || '').toLowerCase().includes(searchTerm) ||
          (sales_status || '').toLowerCase().includes(searchTerm) ||
          (body || '').toLowerCase().includes(searchTerm) ||
          isKeyWordMatch
        const isFieldMatch = this.msgType === 'all' || this.msgType === field
        return isFieldMatch && isSearchMatch
      })
    },
    conversation() {
      return this.$store.state.chosenChatConversation
    },
    reservation() {
      return this.$store.getters.chosenChatReservation
    },
    variables() {
      const { confirmation_code } = this.reservation
      const checkin = this.$moment.parseZone(this.reservation.check_in_time)
      const checkout = this.$moment.parseZone(this.reservation.check_out_time)
      return [
        {
          key: 'guest_name',
          value: this.conversation['guest_full_name'].split(' ')[0],
        },
        {
          key: 'company_name',
          value: this.companyName,
        },
        {
          key: 'confirmation_code',
          value: confirmation_code,
        },
        {
          key: 'comparables',
          value: this.createCompsText(
            this.reservation.chat_relevant_listings,
            this.reservation.source
          ),
        },
        {
          key: 'portal_link',
          value: `https://guest.easyair.io/tourist/trips/${confirmation_code}`,
        },
        {
          key: 'review_link',
          value: `https://guest.easyair.io/reservations/${confirmation_code}/survey`,
        },
        {
          key: 'check_in',
          value: checkin.format('MMM DD, HH:mm'),
        },
        {
          key: 'check_in_time',
          value: checkin.format('HH:mm'),
        },
        {
          key: 'check_in_date',
          value: checkin.format('MMM DD'),
        },
        {
          key: 'check_out',
          value: checkout.format('MMM DD, HH:mm'),
        },
        {
          key: 'check_out_time',
          value: checkout.format('HH:mm'),
        },
        {
          key: 'check_out_date',
          value: checkout.format('MMM DD'),
        },
        {
          key: 'listing_nickname',
          value: this.reservation.listing.nickname,
        },
      ]
    },
    variableKeys() {
      return this.variables.map(v => v.key)
    },
    companyName() {
      return (
        this.reservation.listing?.zone?.company_name ||
        this.$store.state.app.configuration.company_name
      )
    },
  },
  methods: {
    ...mapMutations(['showModal']),
    deleteMsg(id) {
      this.$store.dispatch('deletePreMadeMessage', id)
    },
    editMsg(msg) {
      this.showModal({
        name: 'PreMadeMsgModal',
        props: { msg, variableKeys: this.variableKeys },
      })
    },
    addMsg() {
      this.showModal({
        name: 'PreMadeMsgModal',
        props: { variableKeys: this.variableKeys },
      })
    },
    pushMessage(message) {
      this.preMessage.body += this.preMessage.body
        ? `\n${message.body}`
        : message.body
      this.preMessage.tags.push(message.sales_status)
    },
    chooseMessage(msg) {
      this.variables.map(v => {
        msg.body = msg.body.replace(`{{${v.key}}}`, v.value)
        msg.body = msg.body.replace(`@{${v.key}}`, v.value)
      })
      this.afterMessageChosen(msg)
      this.dialog = false
      this.close()
    },
    removeTag(index) {
      this.preMessage.tags.splice(index, 1)
    },
    close() {
      this.dialog = false
      this.searchTerm = ''
      this.preMessage = {
        tags: [],
        body: '',
      }
    },
  },
}
</script>
<style scoped>
.scroller {
  height: 400px;
}
.break-word {
  word-break: break-word;
}
</style>
