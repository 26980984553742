<template>
  <div class="class ai-train-modal ms-1">
    <!-- <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      required
      min-width="290px"
    > -->
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      required
      min-width="290px"
      absolute
    >
      <template #activator="{ on, attrs }">
        <v-btn
          outlined
          x-small
          color="blue-grey"
          v-bind="attrs"
          v-on="{ ...on, click: onOpen }"
          ><v-icon class="me-1" x-small color="purple darken-4"
            >mdi-creation</v-icon
          >Train</v-btn
        >
      </template>
      <v-card
        outlined
        elevation="1"
        class="pa-4 ai-border pt-0"
        style="width: 100%"
      >
        <div class="d-flex justify-end ma-2 mr-n2">
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-card-title
          v-if="
            step == 2 &&
            !buttonLoading &&
            !detectionLoading &&
            trainType === 'policy'
          "
          class="text-h6 pt-0 justify-center"
          >{{
            !!policyGroup.id ? 'Edit Policy' : 'Create Policy'
          }}</v-card-title
        >
        <v-card-title
          v-if="
            step == 2 &&
            !buttonLoading &&
            !detectionLoading &&
            trainType === 'listing-info'
          "
          class="text-h6 pt-0 justify-center"
          >Create Listing Info Item</v-card-title
        >

        <v-form ref="generatInfoRef" @submit.prevent="generateInfo">
          <div style="display: flex">
            <div
              style="display: flex; flex-direction: column"
              class="train-config-container"
            >
              <v-col
                v-if="step === 1"
                class="d-flex justify-end"
                style="padding: 0 !important"
              >
                <v-btn-toggle
                  v-model="trainType"
                  class="flex-row"
                  color="primary"
                  style="padding: 0 !important"
                >
                  <v-btn value="policy">Policy</v-btn>
                  <v-btn value="property-info">Listing Info</v-btn>
                </v-btn-toggle>
              </v-col>
              <div
                v-if="step === 1 && trainType === 'policy'"
                style="display: flex; flex-direction: column"
              >
                <div
                  v-if="
                    toastVisible && policyGroup.subject && policyGroup.topic
                  "
                  class="toast-container detected-policy-scale-animation"
                >
                  <div class="d-flex align-center toast-content">
                    <!-- <div style="height: 100%">
                    <v-icon color="darken-3" class="mr-2">mdi-information</v-icon>
                  </div> -->
                    <div class="display: flex">
                      <div
                        class="display: flex; flex-direction: row"
                        style="align-items: center; margin: 0 !important"
                      >
                        <v-icon color="darken-3" class="mr-2"
                          >mdi-information</v-icon
                        >
                        <div class="toast-text-title">
                          {{ policyGroup.topic }} &gt;
                          {{ policyGroup.subject }}
                        </div>
                        <div class="conversation-tags">
                          <div class="conversation-tag">POLICY DETECTED</div>
                        </div>
                      </div>
                      <div class="toast-text-description">
                        {{ policyGroup.description }}
                      </div>
                    </div>
                    <div style="height: 100%">
                      <v-btn icon @click="toastVisible = false">
                        <v-icon color="darken-1">mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <!-- <div
                  v-if="
                    policyGroup.description ||
                    policyGroup.topic ||
                    policyGroup.subject
                  "
                  style="display: flex; flex-direction: column"
                >
                  <div style="display: flex; flex-direction: column">
                    <div
                      style="
                        border-radius: 18px;
                        padding: 0;
                        margin-bottom: 10px;
                      "
                    >
                      <div>
                        <v-text-field
                          v-if="false && policyGroup.topic"
                          v-model="policyGroup.topic"
                          disabled
                          label="Detected Topic"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </div>
                    </div>
                    <div
                      style="
                        border-radius: 18px;
                        padding: 0;
                        margin-bottom: 10px;
                      "
                    >
                      <div>
                        <v-text-field
                          v-if="false && policyGroup.subject"
                          v-model="policyGroup.subject"
                          disabled
                          label="Detected Subject"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </div>
                    </div>
                  </div>
                  <div style="display: flex; flex-direction: column">
                    <div
                      style="
                        border-radius: 18px;
                        padding: 0;
                        margin-bottom: 10px;
                      "
                    >
                      <div>
                        <v-textarea
                          v-if="false && policyGroup.description"
                          v-model="policyGroup.description"
                          disabled
                          label="Detected Description"
                          outlined
                          dense
                          hide-details
                          rows="3"
                        ></v-textarea>
                      </div>
                    </div>
                  </div>
                </div> -->
                <!-- <div style="font-size: 13px; margin-bottom: 0 !important">
                  <multi-property-picker
                    v-model="policyGroup.listing_ids"
                    disabled
                    @input="policyChange(policyGroup)"
                  />
                </div> -->
                <div style="display: flex; flex-direction: column">
                  <!-- <div style="display: flex; flex-direction: column">
                    <div style="font-size: 13px">
                      <v-checkbox
                        v-model="policyGroup.pause_on_policy_detection"
                        disabled
                        label="Pause on Policy Detection"
                        dense
                        hide-details
                      />
                    </div>
                    <div style="font-size: 13px">
                      <v-checkbox
                        v-model="policyGroup.escalate_on_policy_detection"
                        disabled
                        label="Escalate on Policy Detection"
                        dense
                        hide-details
                      />
                    </div>
                  </div> -->

                  <div
                    class="ml-3 train-container"
                    style="
                      margin-left: 0 !important;
                      display: flex;
                      flex-direction: column;
                    "
                  >
                    <div style="margin-top: 11px">
                      <v-textarea
                        v-model="badAnswer"
                        disabled
                        label="Bad Answer"
                        outlined
                        dense
                        hide-details
                        rows="3"
                        :rules="[required]"
                      ></v-textarea>
                    </div>
                    <div style="margin-top: 11px">
                      <v-textarea
                        v-model="goodAnswer"
                        label="Good Answer"
                        outlined
                        dense
                        hide-details
                        rows="3"
                        :rules="[required]"
                      ></v-textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="step === 1 && trainType === 'policy'"
                style="display: flex; flex-direction: row; margin-top: 20px"
              >
                <div style="flex-grow: 1">
                  <!-- <v-radio-group
                    v-if="policyGroup.id"
                    v-model="policyGroup.generationOption"
                    dense
                    hide-details
                    row
                  >
                    <v-radio label="Update" value="update_existing" />
                    <v-radio label="Create" value="create_new" />
                  </v-radio-group> -->
                </div>
                <div style="font-size: 13px" class="d-flex justify-end">
                  <v-btn
                    depressed
                    :loading="buttonLoading"
                    class="white--text ai-component-approve-btn"
                    @click="generateInfo"
                  >
                    NEXT
                  </v-btn>
                </div>
              </div>

              <div
                v-if="step === 1 && trainType === 'property-info'"
                style="display: flex; flex-direction: column"
              >
                <div
                  v-if="
                    propertyInfo.topic ||
                    propertyInfo.description ||
                    propertyInfo.subject
                  "
                  style="display: flex; flex-direction: column"
                >
                  <div style="display: flex; flex-direction: column">
                    <div
                      style="
                        border-radius: 18px;
                        padding: 0;
                        margin-bottom: 10px;
                      "
                    >
                      <div>
                        <v-text-field
                          v-if="propertyInfo.topic"
                          v-model="propertyInfo.topic"
                          disabled
                          label="Detected Topic"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </div>
                    </div>
                    <div
                      style="
                        border-radius: 18px;
                        padding: 0;
                        margin-bottom: 10px;
                      "
                    >
                      <div>
                        <v-text-field
                          v-if="propertyInfo.subject"
                          v-model="propertyInfo.subject"
                          disabled
                          label="Detected Subject"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </div>
                    </div>
                  </div>
                  <div style="display: flex; flex-direction: column">
                    <div
                      style="
                        border-radius: 18px;
                        padding: 0;
                        margin-bottom: 10px;
                      "
                    >
                      <div>
                        <v-textarea
                          v-if="propertyInfo.description"
                          v-model="propertyInfo.description"
                          disabled
                          label="Detected Description"
                          outlined
                          dense
                          hide-details
                          rows="3"
                        ></v-textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div style="font-size: 13px; margin-bottom: 0 !important">
                  <multi-property-picker
                    v-model="propertyInfo.listing_ids"
                    disabled
                    @input="policyChange(propertyInfo)"
                  />
                </div> -->
                <!-- <div
                  style="
                    display: flex;
                    flex-direction: column;
                    visibility: hidden;
                  "
                >
                  <div style="font-size: 13px">
                    <v-checkbox
                      v-model="propertyInfo.pause_on_policy_detection"
                      disabled
                      label="Pause on Policy Detection"
                      dense
                      hide-details
                      @change="policyChange(policyGroup)"
                    />
                  </div>
                  <div style="font-size: 13px">
                    <v-checkbox
                      v-model="propertyInfo.escalate_on_policy_detection"
                      disabled
                      label="Escalate on Policy Detection"
                      dense
                      hide-details
                      @change="policyChange(policyGroup)"
                    />
                  </div>
                </div> -->
                <div
                  style="display: flex; flex-direction: column; flex-grow: 1"
                >
                  <div
                    class="ml-3 train-container"
                    style="
                      margin-left: 0 !important;
                      display: flex;
                      flex-direction: column;
                    "
                  >
                    <div style="margin-top: 11px">
                      <v-textarea
                        v-model="badAnswer"
                        disabled
                        label="Bad Answer"
                        outlined
                        dense
                        hide-details
                        rows="3"
                        :rules="[required]"
                      ></v-textarea>
                    </div>
                    <div style="margin-top: 11px">
                      <v-textarea
                        v-model="goodAnswer"
                        label="Good Answer"
                        outlined
                        dense
                        hide-details
                        rows="3"
                        :rules="[required]"
                      ></v-textarea>
                    </div>
                  </div>
                </div>

                <div
                  v-if="step === 1 && trainType === 'property-info'"
                  style="display: flex; flex-direction: row; margin-top: 20px"
                >
                  <div style="flex-grow: 1">
                    <!-- <v-radio-group
                      v-if="propertyInfo.id"
                      v-model="propertyInfo.generationOption"
                      dense
                      hide-details
                      row
                    >
                      <v-radio label="Update" value="update_existing" />
                      <v-radio label="Create" value="create_new" />
                    </v-radio-group> -->
                  </div>
                  <div style="font-size: 13px" class="d-flex justify-end">
                    <v-btn
                      depressed
                      :loading="buttonLoading"
                      class="white--text ai-component-approve-btn"
                      @click="generateInfo"
                    >
                      NEXT
                    </v-btn>
                  </div>
                </div>
              </div>

              <div v-if="step === 2">
                <div
                  v-if="buttonLoading || detectionLoading"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 400px;
                  "
                >
                  <div class="text-center">
                    <ai-anim />
                  </div>
                </div>
                <div v-else-if="trainResult">
                  <div
                    v-if="trainType === 'policy'"
                    style="display: flex; flex-direction: column"
                  >
                    <div
                      v-if="
                        toastVisible && policyGroup.subject && policyGroup.topic
                      "
                      class="toast-container detected-policy-scale-animation"
                    >
                      <div class="d-flex align-center toast-content">
                        <!-- <div style="height: 100%">
                        <v-icon color="darken-3" class="mr-2">mdi-information</v-icon>
                      </div> -->
                        <div class="display: flex">
                          <div
                            class="display: flex; flex-direction: row"
                            style="align-items: center; margin: 0 !important"
                          >
                            <v-icon color="darken-3" class="mr-2"
                              >mdi-information</v-icon
                            >
                            <div class="toast-text-title">
                              {{ policyGroup.topic }} &gt;
                              {{ policyGroup.subject }}
                            </div>
                            <div class="conversation-tags">
                              <div class="conversation-tag">
                                POLICY DETECTED
                              </div>
                            </div>
                          </div>
                          <div class="toast-text-description">
                            {{ policyGroup.description }}
                          </div>
                        </div>
                        <div style="height: 100%">
                          <v-btn icon @click="toastVisible = false">
                            <v-icon color="darken-1">mdi-close</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex; flex-direction: column">
                      <div style="display: flex; flex-direction: column">
                        <div
                          style="
                            border-radius: 18px;
                            padding: 0;
                            margin-bottom: 10px;
                          "
                        >
                          <div>
                            <v-text-field
                              v-model="trainResult.topic"
                              label="Generated Topic"
                              outlined
                              dense
                              hide-details
                              :rules="[required]"
                            ></v-text-field>
                          </div>
                        </div>
                        <div
                          style="
                            border-radius: 18px;
                            padding: 0;
                            margin-bottom: 10px;
                          "
                        >
                          <div>
                            <v-text-field
                              v-model="trainResult.subject"
                              label="Generated Subject"
                              outlined
                              dense
                              hide-details
                              :rules="[required]"
                            ></v-text-field>
                          </div>
                        </div>
                      </div>
                      <div style="display: flex; flex-direction: column">
                        <div
                          style="
                            border-radius: 18px;
                            padding: 0;
                            margin-bottom: 10px;
                          "
                        >
                          <div>
                            <v-textarea
                              v-model="trainResult.description"
                              label="Generated Description"
                              outlined
                              dense
                              hide-details
                              rows="3"
                              :rules="[required]"
                            ></v-textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="font-size: 13px; margin-bottom: 0 !important">
                      <multi-property-picker
                        v-model="trainResult.listing_ids"
                        @input="policyChange(trainResult)"
                      />
                    </div>
                    <div style="display: flex; flex-direction: column">
                      <div style="display: flex; flex-direction: column">
                        <div style="font-size: 13px">
                          <v-checkbox
                            v-model="trainResult.pause_on_policy_detection"
                            label="Pause on Policy Detection"
                            dense
                            hide-details
                          />
                        </div>
                        <div style="font-size: 13px">
                          <v-checkbox
                            v-model="trainResult.escalate_on_policy_detection"
                            label="Escalate on Policy Detection"
                            dense
                            hide-details
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="trainType === 'property-info'"
                    style="display: flex; flex-direction: column"
                  >
                    <div style="display: flex; flex-direction: column">
                      <div style="display: flex; flex-direction: column">
                        <div
                          style="
                            border-radius: 18px;
                            padding: 0;
                            margin-bottom: 10px;
                          "
                        >
                          <div>
                            <v-text-field
                              v-model="trainResult.topic"
                              label="Generated Topic"
                              outlined
                              dense
                              hide-details
                              :rules="[required]"
                            ></v-text-field>
                          </div>
                        </div>
                        <div
                          style="
                            border-radius: 18px;
                            padding: 0;
                            margin-bottom: 10px;
                          "
                        >
                          <div>
                            <v-text-field
                              v-model="trainResult.subject"
                              label="Generated Subject"
                              outlined
                              dense
                              hide-details
                              :rules="[required]"
                            ></v-text-field>
                          </div>
                        </div>
                      </div>
                      <div style="display: flex; flex-direction: column">
                        <div
                          style="
                            border-radius: 18px;
                            padding: 0;
                            margin-bottom: 10px;
                          "
                        >
                          <div>
                            <v-textarea
                              v-model="trainResult.description"
                              label="Generated Description"
                              outlined
                              dense
                              hide-details
                              rows="3"
                              :rules="[required]"
                            ></v-textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="font-size: 13px; margin-bottom: 0 !important">
                      <multi-property-picker
                        v-model="trainResult.listing_ids"
                        @input="policyChange(propertyInfo)"
                      />
                    </div>
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        visibility: hidden;
                      "
                    >
                      <div style="font-size: 13px">
                        <v-checkbox
                          v-model="trainResult.pause_on_policy_detection"
                          label="Pause on Policy Detection"
                          dense
                          hide-details
                          @change="policyChange(policyGroup)"
                        />
                      </div>
                      <div style="font-size: 13px">
                        <v-checkbox
                          v-model="trainResult.escalate_on_policy_detection"
                          label="Escalate on Policy Detection"
                          dense
                          hide-details
                          @change="policyChange(policyGroup)"
                        />
                      </div>
                    </div>
                  </div>
                  <div style="font-size: 13px; margin-top: 20px" class="d-flex">
                    <v-btn
                      depressed
                      :loading="buttonLoading"
                      color="purple lighten-2 me-1"
                      class="white--text"
                      style="margin-left: 4px"
                      @click="generateInfo"
                    >
                      <v-icon class="me-2" small color="white"
                        >mdi-creation</v-icon
                      >
                      REGENERATE
                    </v-btn>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-end;
                      margin-top: 40px;
                    "
                  >
                    <div
                      style="font-size: 13px; margin-top: 20px"
                      class="d-flex justify-end mr-2"
                    >
                      <v-btn
                        depressed
                        :loading="buttonLoading"
                        class="white--text ai-component-reject-btn"
                        @click="
                          () => {
                            toastVisible = true
                            step = 1
                          }
                        "
                      >
                        BACK
                      </v-btn>
                    </div>

                    <div
                      style="font-size: 13px; margin-top: 20px"
                      class="d-flex justify-end"
                    >
                      <v-btn
                        depressed
                        :loading="buttonLoading"
                        class="white--text ai-component-approve-btn"
                        @click="dryRun"
                      >
                        NEXT
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="step === 3">
                <div
                  v-if="buttonLoading || detectionLoading"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 400px;
                  "
                >
                  <div class="text-center">
                    <ai-anim />
                  </div>
                </div>
                <div v-else-if="dryRunResult">
                  <div style="display: flex; flex-direction: column">
                    <dry-run
                      :dry-run-message="dryRunResult"
                      class="d-flex pl-4"
                    />
                    <div
                      style="font-size: 13px; margin-top: 20px"
                      class="d-flex pl-6"
                    >
                      <v-btn
                        depressed
                        :loading="buttonLoading"
                        color="purple lighten-2 me-1"
                        class="white--text"
                        style="margin-left: 14px"
                        @click="dryRun"
                      >
                        <v-icon class="me-2" small color="white"
                          >mdi-creation</v-icon
                        >
                        RE-RUN
                      </v-btn>
                    </div>
                    <div
                      style="
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        margin-top: 40px;
                      "
                    >
                      <div
                        style="font-size: 13px; margin-top: 20px"
                        class="d-flex justify-end mr-2"
                      >
                        <v-btn
                          depressed
                          :loading="buttonLoading"
                          class="white--text ai-component-reject-btn"
                          @click="
                            () => {
                              toastVisible = true
                              step = 2
                            }
                          "
                        >
                          BACK
                        </v-btn>
                      </div>
                      <div
                        style="font-size: 13px; margin-top: 20px"
                        class="d-flex justify-end"
                      >
                        <v-btn
                          depressed
                          :loading="generationLoading"
                          class="white--text ai-component-approve-btn"
                          @click="approve"
                        >
                          APPROVE
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-form>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import FormRules from 'components/mixins/form-rules-mixin'
import multiPropertyPicker from 'components/multi-property-picker'
import DryRun from 'components/conversations/dry-run'
import AiAnim from 'components/loaders/ai-anim.vue'
import axios from '@/axios/config'

const DEPARTMENTS = {
  'AI Sales': 'sales',
  'AI CS': 'cs',
  'AI Operations': 'operations',
}

const TRAIN_TYPES = {
  policy: 'AI Policy',
  listing_item: 'Info Item',
}

export default {
  components: {
    multiPropertyPicker,
    DryRun,
    AiAnim,
  },
  name: 'AiTrainModal',
  props: ['msg', 'listingId', 'conversation', 'trainMessages'],
  mixins: [FormRules],
  data() {
    return {
      TRAIN_TYPES,
      menu: false,
      listingIds: [this.listingId],
      buttonLoading: false,
      generationLoading: false,
      detectionLoading: false,
      goodAnswer: '',
      badAnswer: this.msg.body,
      trainType: 'policy',
      trainData: null,
      policyGroup: { generationOption: 'create_new' },
      propertyInfo: { generationOption: 'create_new' },
      step: 1,
      toastVisible: true,
      trainResult: null,
    }
  },
  computed: {
    isByAi() {
      return Object.keys(DEPARTMENTS).includes(this.msg.rep_name)
    },
    messageToRetrain() {
      if (this.trainMessages && this.trainMessages.length) {
        return this.trainMessages[this.trainMessages.length - 1]
      } else {
        return null
      }
    },
  },
  mounted() {
    this.trainData = null
    if (!this.isByAi) this.goodAnswer = this.msg.body
  },
  methods: {
    async onOpen() {
      this.toastVisible = true
      this.detectionLoading = true
      this.menu = true

      this.$store
        .dispatch('conversations/detectInfo', {
          message_id: this.msg.id,
          bad_answer: this.badAnswer,
          train_type: this.trainType,
        })
        .then(res => {
          if (this.trainType === 'policy') {
            this.policyGroup = {
              generationOption: 'create_new',
              ...res.data.existing_data,
            }
          } else {
            this.propertyInfo = {
              generationOption: 'create_new',
              ...res.data.existing_data,
            }
          }

          this.detectionLoading = false
        })
        .catch(() => {
          this.detectionLoading = false
        })
    },
    policyChanged(policy) {
      return policy.changed //&& !this.policyUpdated
    },
    policyChange(policy) {
      policy.changed = true
      // this.policyUpdated = false
    },
    close() {
      this.menu = false
      this.trainResult = null
      this.buttonLoading = false
      this.detectionLoading = false
      this.step = 1
      this.policyGroup = { generationOption: 'create_new' }
      this.propertyInfo = { generationOption: 'create_new' }
      this.good_answer = ''
      this.generationLoading = false
      this.toastVisible = true
    },
    // async changeGoodAnswer() {
    //   this.policyGroup = { generationOption: 'create_new' }
    //   this.propertyInfo = { generationOption: 'create_new' }
    // },
    async generateInfo() {
      const startStep = this.step

      if (
        this.step === 1 &&
        ((this.policyGroup.id && this.trainType === 'policy') ||
          this.propertyInfo.id)
      ) {
        this.step = 2
        this.toastVisible = true
      }
      if (this.step !== 2 && !this.$refs.generatInfoRef.validate()) return
      this.buttonLoading = true
      this.step = 2
      this.$store
        .dispatch('conversations/generateInfo', {
          message_id: this.msg.id,
          good_answer: this.goodAnswer,
          bad_answer: this.badAnswer,
          train_type: this.trainType,
        })
        .then(res => {
          this.trainResult = res.data.train_data
          if (this.trainType === 'policy') {
            this.policyGroup = {
              generationOption: 'create_new',
              ...res.data.existing_data,
            }
          } else {
            this.propertyInfo = {
              generationOption: 'create_new',
              ...res.data.existing_data,
            }
          }
          if (res.data.existing_data.id && this.startStep === 1) {
            this.step = 1
          }
          this.buttonLoading = false
          this.toastVisible = true
        })
    },
    async dryRun() {
      if (!this.$refs.generatInfoRef.validate()) return

      this.buttonLoading = true
      this.step = 3

      let existing_policy_id = null
      if (this.trainType === 'policy') {
        if (
          this.propertyInfo.generationOption === 'update_existing' &&
          this.propertyInfo.id
        ) {
          existing_policy_id = this.propertyInfo.id
        }
      } else {
        if (
          this.policyGroup.generationOption === 'update_existing' &&
          this.policyGroup.id
        ) {
          existing_policy_id = this.policyGroup.id
        }
      }

      axios.post(`/api/conversations/dry-run`, {
        original_message_id: this.msg.id,
        subject: this.trainResult.subject,
        topic: this.trainResult.topic,
        description: this.trainResult.description,
        existing_policy_id: existing_policy_id,
      })

      const interval = setInterval(() => {
        axios
          .get(`/api/conversations/dry-run`, {
            params: {
              original_message_id: this.msg.id,
            },
          })
          .then(res => {
            this.dryRunResult = res.data.dry_run

            if (this.dryRunResult) {
              clearInterval(interval)
              this.buttonLoading = false
              this.toastVisible = true
            }
          })
          .catch(error => {
            console.error('Error fetching dry-run guest request:', error)
            clearInterval(interval)
          })
      }, 4000)
    },
    async approve() {
      if (this.trainType === 'policy') {
        this.upsertPolicy()
      } else this.createInfoItem()
      this.toastVisible = true
    },
    createInfoItem() {
      this.generationLoading = true
      let trainId = null
      if (
        // this.propertyInfo.generationOption === 'update_existing' &&
        this.propertyInfo.id
      ) {
        trainId = this.propertyInfo.id
      }
      this.$store
        .dispatch('conversations/createInfoItem', {
          id: trainId,
          subject: this.trainResult.subject,
          topic: this.trainResult.topic,
          description: this.trainResult.description,
          listing_ids: this.trainResult.listingIds,
        })
        .then(() => {
          this.close()
        })
    },

    upsertPolicy() {
      this.generationLoading = true

      const trainId = this.policyGroup?.id
      const conversationsModule = trainId
        ? 'conversations/updatePolicy'
        : 'conversations/createPolicy'

      this.$store
        .dispatch(conversationsModule, {
          id: trainId ? trainId : null,
          pause_on_policy_detection: this.trainResult.pause_on_policy_detection,
          escalate_on_policy_detection:
            this.trainResult.escalate_on_policy_detection,
          listing_ids: this.trainResult.listing_ids,
          subject: this.trainResult.subject,
          topic: this.trainResult.topic,
          description: this.trainResult.description,
          department: this.msg.department,
        })
        .then(() => {
          this.close()
        })
    },
  },
}
</script>

<style scoped>
.ai-train-modal {
  transform: scale(0.9);
  z-index: 8888;
}
.gap-15 {
  grid-gap: 15px;
}

:deep() textarea {
  white-space: pre-line;
}

.ai-border {
  border: 1px solid;
  border-radius: 18px !important;
  border-color: #ba68c8 !important;
  height: 100%;
}
.shift-train-left {
  left: 3px !important;
  bottom: 3px !important;
  top: auto !important;
  border-radius: 18px !important;
  position: 'absolute';
  max-width: 100% !important;
  max-height: 100% !important;
  height: 100% !important;
}
.train-config-container {
  width: 40em;
  height: 100%;
  padding: 0 !important;
}
.train-config-container > div {
  flex-grow: 0 !important;
}

.v-menu__content {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  border-radius: 18px !important;
  /* height: 620px; */
}

.train-container {
  /* height: 85vh; */
}

.v-input--radio-group {
  margin-top: 0 !important;
}

.ai-component-reject-btn {
  background-color: var(--v-warning-base) !important;
  color: white;
}
.ai-component-approve-btn {
  background-color: var(--v-success-base) !important;
  color: white;
}
.v-input--radio-group--row .v-input--radio-group__input {
  flex-wrap: auto !important;
}
.conversation-tags {
  display: flex;
  flex-wrap: wrap;
}
.conversation-tag {
  font-family: ui-monospace;
  background: var(--v-primary-base);
  color: white;
  margin-top: 12px;
  margin-left: 8px;
  margin-bottom: 7px;
  padding-left: 11px;
  padding-right: 13px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  font-size: 9px;
}
.toast-container {
  border-radius: 9px;
  width: 100%;
  background-color: var(--v-primary-lighten1);
  border: 1px solid var(--v-primary-darken1);
  padding: 10px 15px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px; /* Adds space between the toast and other elements */
  margin-top: 15px; /* Adds space between the toast and other elements */
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.toast-text-title {
  color: darkolivegreen;
}

.toast-text-description {
  flex-grow: 1;
  color: darkslategray;
  padding-bottom: 19px;
}

.toast-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.detected-policy-scale-animation {
  animation: detected-policy-scale 6s infinite;
}

@keyframes detected-policy-scale {
  0% {
    transform: scale(0.99);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.99);
  }
}
</style>
