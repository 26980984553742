<template>
  <v-stepper-content :step="step">
    <div class="text-h6">{{ $t('Summary') }}</div>
    <div class="text-subtitle-1">{{ currentListingTask.description }}</div>
    <v-divider class="my-2" />
    <div class="d-flex">
      <v-avatar tile color="rounded elevation-2">
        <v-icon color="black">mdi-google-maps</v-icon>
      </v-avatar>
      <div
        class="d-flex flex-column justify-space-between align-start px-3 py-2"
      >
        <span class="text-caption">{{ $t('Location') }}</span>
        <span class="text-body-2 font-weight-medium ellipsis-1">{{
          listing.address
        }}</span>
      </div>
    </div>
    <v-row no-gutters class="my-2">
      <v-col v-if="duration" cols="6">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <div class="d-flex">
              <v-avatar tile color="rounded elevation-2">
                <v-icon color="black">mdi-clock-outline</v-icon>
              </v-avatar>

              <div
                class="d-flex flex-column justify-space-between align-start px-3 py-2"
                v-on="on"
              >
                <span class="text-caption"
                  >{{ $t('Total') }}
                  <span
                    ><v-icon small> {{ $t('info') }} </v-icon></span
                  ></span
                >
                <span class="text-body-2 font-weight-medium ellipsis-1"
                  >{{ duration.toFixed(1) }} h</span
                >
              </div>
            </div>
          </template>
          <div>
            <div v-for="(clock, ind) in clockIns" :key="ind">
              <div class="d-flex justify-space-between align-center">
                <span class="text-caption">{{
                  parseDate(clock.clocked_in, 'HH:mm', { local: true })
                }}</span>
                <span class="mx-1">-</span>
                <span class="text-caption">{{
                  parseDate(clock.clocked_out || new Date(), 'HH:mm', {
                    local: true,
                  })
                }}</span>
              </div>
            </div>
          </div>
        </v-tooltip>
      </v-col>
      <v-col v-if="startTime && endTime" cols="6">
        <div class="d-flex">
          <v-avatar tile color="rounded elevation-2">
            <v-icon color="black">mdi-clock-outline</v-icon>
          </v-avatar>
          <div
            class="d-flex flex-column justify-space-between align-start px-3 py-2"
          >
            <span class="text-caption">{{ $t('Time') }}</span>
            <span class="text-body-2 font-weight-medium ellipsis-1"
              >{{ startTime }} - {{ endTime }}</span
            >
          </div>
        </div>
      </v-col>
    </v-row>
    <v-divider />
    <expander
      v-if="hasSubTasks"
      :title="`${$t('Done Tasks')} (${doneTasks}/${
        currentListingTask.sub_tasks.length
      })`"
    >
      <div class="pa-2 white">
        <div v-for="listSubject in listSubjects" :key="listSubject">
          <lt-subtask-list
            :title="listSubject"
            class="mt-2"
            :listing-task="currentListingTask"
            :tasks="filterBySubject(listSubject)"
          />
        </div>
      </div>
    </expander>
    <v-textarea
      v-model="currentListingTask.after_comments"
      :label="$t('Comments')"
      :rows="3"
      outlined
      dense
      @change="afterCommentsChanged"
    />
    <div>
      <div class="text-subtitle-1 font-weight-medium">
        {{ $t('Add Media/Invoice') }}
      </div>
      <Gallery
        :thumbnails="true"
        :cols="4"
        :listing-id="currentListingTask.listing_id"
        :images="
          currentListingTask.receipt_images.concat(
            currentListingTask.after_images
          )
        "
        folder="service-calls"
        :rotate-image="rotateAfter"
        :remove-img="removeAfter"
        :on-change="addRecieptImages"
        :uploadable="true"
      />
      <v-text-field
        v-if="currentListingTask.receipt_images.length !== 0"
        v-model="invoice_number"
        :label="$t('Invoice number')"
        dense
        outlined
        class="mt-4"
        @input="debouncedInput({ invoice_number })"
      />
    </div>
    <add-repair-materials
      v-if="!(isContractor && !onPayroleContractor)"
      :task-id="currentListingTask.id"
    />
    <div
      v-for="expense in currentListingTask.material_expenses"
      :key="expense.id"
      class="d-flex align-center py-2"
    >
      <span
        class="info--text pointer col-6 pa-0"
        @click="showMaterialsExpense(expense)"
      >
        {{ $t(expense.short_reason) }}
      </span>
      <div class="pa-0 px-4 text-subtitle-2 ml-auto">
        {{ dollarFormatter(expense.amount) }}
      </div>
      <confirmation-modal
        text="Are you sure you want to delete this expense?"
        @action="removeMaterial(expense.id)"
      >
        <template #activator="{ on }">
          <v-icon color="warning" small v-on="on">fas fa-minus-circle</v-icon>
        </template>
      </confirmation-modal>
    </div>
    <v-text-field
      v-model.number="totalHours"
      class="mt-2"
      :label="$t('Time spent (Hours)')"
      type="number"
      :hint="clockInHint"
      dense
      outlined
      :disabled="hoursDisabled"
      @input="debouncedInput({ total_hours: totalHours })"
    />
    <div v-if="!isCleaner && (!isContractor || !onPayroleContractor)">
      <v-text-field
        v-model.number="amount"
        :label="$t('Amount')"
        type="number"
        dense
        outlined
        :persistent-hint="!subTasksDone"
        :hint="amountHint"
        :disabled="isAmountDisabled"
        class="px-md-4"
        @input="debouncedInput({ amount })"
      />
      <div class="black--text flex col-6 pa-0">
        {{ $t('Total charged') }}
      </div>
      <div class="flex col-12 pa-0">
        <v-text-field
          :value="amount + materialsSum"
          class="px-md-4"
          dense
          disabled
          type="number"
        />
      </div>
    </div>
  </v-stepper-content>
</template>

<script>
import ListingTaskViewMixin from 'components/listing-tasks/mixins/listing-task-view-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import LtSubtaskList from 'components/listing-tasks/lt-subtask-list.vue'
import uniq from 'lodash/uniq'
import map from 'lodash/map'
import compact from 'lodash/compact'
import Expander from 'components/common/expander.vue'
import Common_functions from 'components/mixins/common_functions'
import Gallery from 'components/common/gallery.vue'
import last from 'lodash/fp/last'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { get, getOr, isNumber, sumBy } from 'lodash/fp'
import { mapGetters, mapState } from 'vuex'
import debounce from 'lodash/debounce'
import AddRepairMaterials from 'components/listing-tasks/add-repair-materials.vue'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'
export default {
  name: 'SummaryStep',
  components: {
    ConfirmationModal,
    AddRepairMaterials,
    Gallery,
    Expander,
    LtSubtaskList,
  },
  mixins: [
    ListingTaskViewMixin,
    Common_functions,
    PermissionsMixin,
    FormattersMixin,
  ],
  props: ['step', 'isLastStep'],
  data() {
    return {
      totalHours: null,
      amount: null,
      invoice_number: null,
    }
  },
  mounted() {
    const catalogTime = getOr(
      null,
      'listing_task_catalog_item.avg_duration',
      this.currentListingTask
    )
    this.totalHours =
      this.currentListingTask.total_hours ||
      catalogTime ||
      this.duration.toFixed(1)
    if (this.totalHours === null) {
      this.totalHours = +this.duration.toFixed(1)
    }
    this.amount = this.currentListingTask.amount
    this.invoice_number = this.currentListingTask.invoice_number
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapGetters('users', ['usersMap']),
    amountHint() {
      if (!this.subTasksDone) {
        return this.$t('Not all tasks are done')
      }
      return ''
    },
    materialsSum() {
      return sumBy('amount', this.currentListingTask.material_expenses) || 0
    },
    isAmountDisabled() {
      if (this.ruleFixedPrice && !this.managementRoles) {
        return true
      }
      if (this.isDesignedVr && this.externalContractor) {
        return true
      }
      if (!this.subTasksDone) {
        return true
      }
      return (
        this.currentListingTask.is_paid ||
        this.currentListingTask.manual_approved ||
        (!this.onPayroleContractor && this.isContractor && this.taskDone)
      )
    },
    hoursDisabled() {
      return (
        this.currentListingTask.manual_approved ||
        (this.taskDone && !this.managementRoles)
      )
    },
    ruleFixedPrice() {
      const ruleAmount = get(
        'auto_listing_task_rule.amount',
        this.currentListingTask
      )
      return isNumber(ruleAmount)
    },
    totalAmount() {
      let totalHours = this.totalHours
      if (this.externalAssignee) {
        return this.amount
      }
      if (
        this.payrollAssignee &&
        parseFloat(this.contractor.dvr_hourly_chrage) > 0
      ) {
        return totalHours * this.contractor.dvr_hourly_chrage
      }
      const ruleAmount = get(
        'auto_listing_task_rule.amount',
        this.currentListingTask
      )
      if (isNumber(ruleAmount)) {
        return ruleAmount
      }
      const avgPrice = get(
        'mini_extra_data.avg_price_per_hour',
        this.currentListingTask
      )
      if (totalHours != null && avgPrice != null) {
        return totalHours * avgPrice
      }
      return this.amount
    },
    clockInHint() {
      let res = this.duration.toFixed(1) + ' Hours (by clock-ins)'
      if (this.currentListingTask.override_hours) {
        res += ' * Hours manually updated'
      }
      return res
    },
    listSubjects() {
      let subjects = []
      if (this.hasSubTasks) {
        subjects = uniq(map(this.currentListingTask.sub_tasks, 'list_subject'))
        if (subjects.includes(null)) {
          subjects.push('Sub Tasks')
        }
      }
      return compact(subjects).sort().reverse()
    },
    listing() {
      return this.currentListingTask.listing || {}
    },
    doneTasks() {
      return this.currentListingTask.sub_tasks.filter(
        subT =>
          subT.is_valid !== null ||
          subT.status === 'Done' ||
          subT.status === "Can't do"
      ).length
    },
    clocking() {
      return last(this.clockIns) || {}
    },
    clockIns() {
      return this.currentListingTask.working_cleaners.filter(
        c =>
          c.user_id === this.currentUserId ||
          c.listing_task_id === this.currentListingTask.id
      )
    },
    startTime() {
      return this.parseDate(this.clocking.clocked_in, 'HH:mm', { local: true })
    },
    endTime() {
      const clockout = this.clocking.clocked_out || this.$moment()
      return this.parseDate(clockout, 'HH:mm', { local: true })
    },
    duration() {
      let active_duration = 0
      let activeClocks = this.clockIns.filter(clock => !clock.duration)
      if (activeClocks.length > 0) {
        activeClocks.forEach(clock => {
          active_duration += this.$moment
            .utc()
            .diff(this.$moment.utc(clock.clocked_in), 'minutes')
        })
      }
      let total_duration = this.clockIns.reduce((sum, clock) => {
        return sum + (clock.duration || 0) // Add duration if exists, otherwise 0
      }, 0)
      return (total_duration + active_duration) / 60
    },
    contractor() {
      if (
        this.currentListingTask.assigned_contractor_id === this.currentUser.id
      ) {
        return this.currentUser
      }
      return this.usersMap[this.currentListingTask.assigned_contractor_id] || {}
    },
    externalAssignee() {
      return (
        this.contractor.role === 'contractor' && !this.contractor.on_payrole
      )
    },
    payrollAssignee() {
      return this.contractor.role === 'contractor' && this.contractor.on_payrole
    },
    taskDone() {
      return this.isDone(this.currentListingTask.status)
    },
  },
  watch: {
    isLastStep(value) {
      if (value && !this.taskDone && !this.currentListingTask.total_hours) {
        this.totalHours = +this.duration.toFixed(1)
        this.currentListingTask.total_hours = +this.duration.toFixed(1)
      }
    },
    'currentListingTask.total_hours'(val) {
      if (val && !isNaN(val)) {
        this.amount = this.totalAmount
      }
    },
    payrollAssignee: {
      handler: 'checkAndUpdateAmount',
      immediate: true,
    },
  },
  methods: {
    removeMaterial(id) {
      this.$store.dispatch('removeRepairMaterials', {
        id: this.currentListingTask.id,
        expenseId: id,
      })
    },
    showMaterialsExpense(expense) {
      this.$store.commit('showModal', {
        name: 'ExpenseCard',
        props: {
          expense,
          listingTask: this.currentListingTask,
        },
      })
    },
    debouncedInput: debounce(async function (payload) {
      this.$emit('toggle-loading', true)
      if (payload.total_hours) {
        payload.override_hours = true
      }
      await this.updateTask(payload)
      this.$emit('toggle-loading', false)
    }, 400),
    afterCommentsChanged(val) {
      this.updateTask({
        after_comments: val,
      })
    },
    rotateAfter(oldLink) {
      return newLink => {
        const after_images = this.currentListingTask.after_images.map(img =>
          img === oldLink ? newLink : img
        )
        this.updateTask({ after_images })
      }
    },
    removeAfter(link) {
      const after_images = this.currentListingTask.after_images.filter(
        img => img !== link
      )
      this.updateTask({ after_images })
    },
    addRecieptImages(images) {
      this.updateTask({
        receipt_images: this.currentListingTask.receipt_images.concat(images),
      })
      this.sendImagesUpdatedNotification()
    },
    checkAndUpdateAmount() {
      if (this.payrollAssignee && this.totalAmount && this.amount === null) {
        this.amount = this.totalAmount
      }
    },
  },
}
</script>

<style scoped></style>
