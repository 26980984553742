<template>
  <v-input :value="value" :rules="rules" :hide-details="hideDetails">
    <vue-tel-input
      class="w-100"
      :value="inputValue"
      valid-characters-only
      :input-options="{
        placeholder: placeholder,
        styleClasses: 'phone-input w-100',
        autocomplete: 'on',
      }"
      @focus="$emit('focus')"
      @input="onInput"
    >
      <template #arrow-icon><v-icon>mdi-chevron-down</v-icon> </template>
    </vue-tel-input>
  </v-input>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Phone',
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      number: undefined,
      phoneObject: {},
      errorMessages: [],
    }
  },
  watch: {
    value(v) {
      if (v !== (this.phoneObject.number || '')) {
        this.number = undefined
      }
    },
  },
  computed: {
    inputValue() {
      return this.number || this.value
    },
    rules() {
      return this.required ? [this.requiredRule, this.valid] : [this.valid]
    },
  },
  methods: {
    valid() {
      return (
        !this.phoneObject || this.phoneObject.valid !== false || 'Invalid Phone'
      )
    },
    requiredRule(v) {
      return !!v || 'Required Field'
    },
    onInput(number, phoneObject) {
      if (phoneObject && phoneObject.number) {
        this.phoneObject = phoneObject
        this.$emit('input', phoneObject.number)
        this.number = number
      }
    },
  },
}
</script>

<style>
.phone-input {
  max-height: none !important;
}
.vue-tel-input {
  border-color: var(--v-secondary-base);
}
.vue-tel-input:focus-within {
  box-shadow: none !important;
  border-color: var(--v-primary-base);
  border-width: 2px;
}
.error--text .vue-tel-input {
  border-color: var(--v-error-base);
  border-width: 2px;
  border-radius: 4px;
}
.vti__dropdown-list {
  padding-left: 0 !important;
  min-width: 300px;
}
.vti__dropdown-item {
  font-size: 12px;
}
</style>
