<template>
  <v-stepper-content class="fill-height" :step="step">
    <div>
      <v-img
        v-if="listing.picture"
        :src="listing.picture"
        height="150"
        width="100%"
      />
      <div
        class="text-caption font-weight-medium mt-2 d-flex justify-space-between"
      >
        <div class="grey--text lighten-2">
          TicketID: #{{ currentListingTask.id }}
        </div>
        <div><gallery :images="pics" icon="images" :readonly="true" /></div>
      </div>
      <div
        v-if="currentListingTask.mini_extra_data.next_res_info"
        class="mt-4 d-flex justify-space-between"
      >
        <task-reservation-tooltip
          label="Next Reservation"
          :reservation="currentListingTask.mini_extra_data.next_res_info"
        />
        <div v-if="checkinInstructions">
          <v-tooltip
            v-model="showArrivalInstructions"
            content-class="info-tooltip"
            bottom
          >
            <template #activator="{ attrs }">
              <v-chip
                v-click-outside="() => (showArrivalInstructions = false)"
                small
                label
                v-bind="attrs"
                color="green lighten-5"
                class="font-weight-semibold mb-2"
                @click="showArrivalInstructions = true"
                >Arrival Instructions
                <v-icon x-small class="ml-1">$map_directions</v-icon></v-chip
              >
            </template>
            <div>
              <div class="d-flex justify-end">
                <v-btn icon @click="showArrivalInstructions = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <div class="text-caption mt-2" style="white-space: pre-line">
                {{ checkinInstructions }}
                <gallery
                  :thumbnails="true"
                  :cols="4"
                  :images="instructionsMedia"
                  :readonly="true"
                />
              </div>
              <v-divider class="my-4" />
            </div>
          </v-tooltip>
        </div>
      </div>
      <div class="text-h6 my-2">
        {{ currentListingTask.description }}
      </div>
      <div
        v-if="currentListingTask.internal_comments && !isInvestor"
        class="mb-2"
      >
        <div class="text-subtitle-1 secondary--text">Note:</div>
        <div>{{ currentListingTask.internal_comments }}</div>
      </div>
      <div class="d-flex">
        <v-avatar tile color="rounded elevation-2">
          <v-icon color="black">mdi-google-maps</v-icon>
        </v-avatar>
        <div
          class="d-flex flex-column justify-space-between align-start px-3 py-2"
        >
          <span class="text-caption">{{ $t('Listing') }}</span>
          <span class="text-body-2 font-weight-medium ellipsis-1">{{
            listing.nickname
          }}</span>
        </div>
      </div>
      <div class="d-flex">
        <v-avatar tile color="rounded elevation-2">
          <v-icon color="black">mdi-clock-outline</v-icon>
        </v-avatar>
        <div
          class="d-flex flex-column justify-space-between align-start px-3 py-2"
        >
          <span class="text-caption">{{ $t('Scheduled at') }}</span>
          <span
            v-if="currentListingTask.scheduled_at"
            class="text-body-2 font-weight-medium ellipsis-1"
            >{{ parseDateTZ(currentListingTask.scheduled_at, 'HH:mm') }} -
            {{ endTime }}</span
          >
          <span v-else class="text-body-2 font-weight-medium ellipsis-1"
            >N/A</span
          >
        </div>
      </div>
      <div v-if="currentReview" class="d-flex">
        <v-avatar tile color="rounded elevation-2">
          <v-icon color="black">mdi-home-assistant</v-icon>
        </v-avatar>
        <div
          class="d-flex flex-column justify-space-between align-start px-3 py-2"
        >
          <span class="text-caption">{{ $t('Review') }}</span>
          <span>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <span
                  class="text-body-2 font-weight-medium text-decoration-underline primary--text pointer"
                  color="primary"
                  v-on="on"
                  >Details <v-icon v-bind="attrs" small> $info </v-icon></span
                >
              </template>
              <review-card :review="currentReview" />
            </v-tooltip>
          </span>
        </div>
      </div>
      <div class="d-flex">
        <v-avatar tile color="rounded elevation-2">
          <v-icon color="black">mdi-lock</v-icon>
        </v-avatar>
        <div
          class="d-flex flex-column justify-space-between align-start px-3 py-2"
        >
          <span class="text-caption">{{ $t('Door Code') }}</span>
          <span>
            <confirmation-modal
              v-if="!showCodes"
              :text="`Please knock 3 times and wait 10 seconds before entry. (<b>${currentListingTask.add_today_avialability_text}</b>)`"
              @action="showCodes = true"
            >
              <template #activator="{ on }">
                <span
                  class="text-body-2 font-weight-medium text-decoration-underline primary--text pointer"
                  v-on="on"
                  >Show codes
                </span>
              </template>
            </confirmation-modal>
            <span v-else class="text-body-2 font-weight-medium">
              {{ currentListingTask.door_code }}
            </span>
          </span>
        </div>
      </div>
      <div class="d-flex">
        <v-avatar tile color="rounded elevation-2">
          <v-icon color="black">mdi-calendar-check</v-icon>
        </v-avatar>
        <div
          class="d-flex flex-column justify-space-between align-start px-3 py-2"
        >
          <span class="text-caption">{{ $t('Availability') }}</span>
          <span class="text-body-2 font-weight-medium ellipsis-1">{{
            $t(currentListingTask.listing_available_time)
          }}</span>
        </div>
      </div>
      <div v-if="createdBy" class="d-flex">
        <v-avatar tile color="rounded elevation-2">
          <v-icon color="black">mdi-account</v-icon>
        </v-avatar>
        <div
          class="d-flex flex-column justify-space-between align-start px-3 py-2"
        >
          <span class="text-caption">{{ $t('Created By') }}</span>
          <div class="text-body-2 font-weight-medium ellipsis-1">
            <span>{{ createdBy.name }}</span>
            <v-btn
              v-if="createdBy.phone"
              class="mx-2"
              :href="`tel:${createdBy.phone}`"
              x-small
              outlined
              color="info"
            >
              <v-icon size="12">fas fa-phone</v-icon>
            </v-btn>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <div class="text-subtitle-1 font-weight-medium">{{ $t('Media') }}</div>
        <gallery
          :cols="4"
          :readonly="true"
          :thumbnails="true"
          :uploadable="true"
          folder="service-calls"
          :listing-id="currentListingTask.listing_id"
          :remove-img="removeBeforeImg"
          :rotate-image="rotateBeforeImg"
          :on-change="addBeforeImages"
          :images="
            currentListingTask.before_images.concat(
              currentListingTask.desc_images
            )
          "
        />
      </div>
      <div class="text-subtitle-1 font-weight-medium mt-4">
        {{ $t('House Info') }}
      </div>
      <v-divider />
      <v-expansion-panels flat class="z-0">
        <v-expansion-panel v-for="(category, key) in categories" :key="key">
          <v-expansion-panel-header
            class="px-0 font-weight-medium text-body-2"
            expand-icon="mdi-menu-down"
            >{{ category.title }}</v-expansion-panel-header
          >
          <v-expansion-panel-content class="sections-panel pe-4">
            <v-expansion-panels flat>
              <v-expansion-panel
                v-for="(section, sectionKey) in category.sections"
                :key="sectionKey"
              >
                <v-expansion-panel-header
                  class="px-0 font-weight-medium text-body-2"
                  expand-icon="mdi-menu-down"
                >
                  <span>
                    <span
                      v-for="(header, i) in section.headers"
                      :key="header"
                      class="text-caption text-capitalize mt-2 pb-2 font-weight-medium"
                    >
                      <span> {{ header }}</span>
                      <v-icon v-if="section.headers.length - 1 !== i" small
                        >mdi-chevron-right</v-icon
                      >
                    </span></span
                  ></v-expansion-panel-header
                >
                <v-expansion-panel-content class="inner-panel">
                  <v-row>
                    <v-col cols="12" class="overflow-hidden pt-0">
                      <div
                        style="white-space: pre-line"
                        class="text-caption mt-1"
                      >
                        {{ section.comments }}
                      </div>
                      <gallery
                        button-height="350px"
                        :thumbnails="true"
                        :cols="12"
                        :images="section.images"
                      />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
                <v-divider />
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-stepper-content>
</template>

<script>
import Gallery from 'components/common/gallery.vue'
import TaskReservationTooltip from 'components/todays-tasks/task-reservation-tooltip.vue'
import Common_functions from 'components/mixins/common_functions'
import ListingTaskViewMixin from 'components/listing-tasks/mixins/listing-task-view-mixin'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import get from 'lodash/fp/get'
import ReviewCard from 'components/reviews/review-card.vue'
import { groupBy, orderBy } from 'lodash'

export default {
  name: 'InitialStep',
  mixins: [Common_functions, ListingTaskViewMixin, PermissionsMixin],
  components: {
    ReviewCard,
    ConfirmationModal,
    Gallery,
    TaskReservationTooltip,
  },
  props: ['step'],
  data() {
    return {
      showCodes: false,
      dialog: false,
      showArrivalInstructions: false,
    }
  },
  computed: {
    listing() {
      return this.currentListingTask.listing || {}
    },
    checkinInstructions() {
      return this.currentListingTask.listing?.checkin_instructions
    },
    instructionsMedia() {
      return this.currentListingTask.listing?.checkin_instructions_media?.map(
        m => m.src
      )
    },
    currentReview() {
      return this.currentListingTask.review
    },
    catalogKey() {
      return get('listing_task_catalog_item.key', this.currentListingTask)
    },
    listingAvailable() {
      return (
        this.currentListingTask.door_code &&
        !this.isDone(this.currentListingTask.status) &&
        this.currentListingTask.listing_available_time !== 'Occupied'
      )
    },
    createdBy() {
      return this.usersMap[this.currentListingTask.created_by_id]
    },
    endTime() {
      return this.$moment(this.currentListingTask.scheduled_at)
        .add(this.currentListingTask.planned_duration, 'hours')
        .utc()
        .format('HH:mm')
    },
    pics() {
      return this.currentListingTask.listing?.pictures.map(p =>
        (p.original || p.thumbnail)?.replace('/upload/', '/upload/c_fit,h_300/')
      )
    },
    categories() {
      const filteredGuestsInfo =
        this.currentListingTask.listing?.listing_info_items.filter(
          info => info.listing_info_def_id
        )
      const categories = groupBy(filteredGuestsInfo, 'listing_info_def.topic')
      const grouped = Object.keys(categories).map(key => ({
        title: key,
        sections: this.sections(categories[key]),
      }))
      return orderBy(grouped, 'title')
    },
  },
  methods: {
    sections(categoryInfo) {
      const orderedCategories = orderBy(
        categoryInfo,
        'listing_info_def.sub_topic'
      )
      return (orderedCategories || []).map(info => {
        const { listing_info_def, comments, images } = info
        const { sub_topic, hint } = listing_info_def
        return {
          headers: [sub_topic, hint].filter(Boolean),
          comments: comments,
          images: images,
        }
      })
    },
    removeBeforeImg(link) {
      const before_images = this.currentListingTask.before_images.filter(
        img => img !== link
      )
      this.updateTask({ before_images })
    },
    rotateBeforeImg(oldLink) {
      return newLink => {
        const before_images = this.currentListingTask.before_images.map(img =>
          img === oldLink ? newLink : img
        )
        this.updateTask({ before_images })
      }
    },
    addBeforeImages(imgs) {
      this.updateTask({
        before_images: this.currentListingTask.before_images.concat(imgs),
      })
      this.sendImagesUpdatedNotification()
    },
    close() {
      this.dialog = false
    },
  },
}
</script>

<style scoped>
.z-0 {
  z-index: 0;
}
.inner-panel >>> .v-expansion-panel-content__wrap {
  padding: 0;
}
.sections-panel >>> .v-expansion-panel-content__wrap {
  padding: 0;
}
.chip {
  left: 50%;
  font-size: 11px;
  padding: 11px;
}
.info-tooltip {
  pointer-events: all !important;
  width: 95%;
  background-color: white !important;
  opacity: 1 !important;
  border-radius: 8px;
  color: black;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  margin-top: 50px;
}
</style>
