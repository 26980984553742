import axios from '@/axios/config'

export default {
  namespaced: true,
  state: {
    expensesTypes: [],
    expenseSettings: [],
  },
  mutations: {
    setExpensesTypes(state, value) {
      state.expensesTypes = value
    },
    setExpenseSettings(state, value) {
      state.expenseSettings = value
    },
  },
  getters: {
    expenseTypesNames(state) {
      return [...new Set(state.expensesTypes.map(item => item.name))] || []
    },
    expenseTypesBusiness(state) {
      const expensesTypesFiltered = state.expensesTypes.filter(
        item => item.expense_role === 'business'
      )
      return [...new Set(expensesTypesFiltered.map(item => item.name))] || []
    },
    expenseTypesClient(state) {
      const expensesTypesFiltered = state.expensesTypes.filter(
        item => item.expense_role === 'client'
      )
      return [...new Set(expensesTypesFiltered.map(item => item.name))] || []
    },
  },
  actions: {
    async fetchAllExpenseTypes({ commit }, params) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios
        .get(`/api/expense-types`, { params })
        .then(res => {
          commit('setExpensesTypes', res.data.items)
          commit('setExpenseSettings', res.data.quantifier)
          return res.data
        })
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    async createNewExpenseType({ commit }, data) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios
        .post(`/api/expense-types`, data)
        .then(res => res.data)
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    async updateExpenseType({ commit }, data) {
      const { id, name, category, quantifier, units, amount } = data
      const body = { name, category, quantifier, units, amount }

      commit('updateHeaderProgressBar', true, { root: true })
      return axios
        .put(`/api/expense-types/${id}`, body)
        .then(res => res.data)
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    async removeExpenseType({ commit }, id) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios
        .delete(`/api/expense-types/${id}`)
        .then(res => res.data)
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
  },
}
