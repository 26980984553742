<template>
  <div>
    <v-row>
      <v-col cols="2">
        <v-select
          v-model="cityFilter"
          clearable
          label="City"
          multiple
          :items="$store.state.cities"
          class="cyan-icon"
          @change="filterChange"
        />
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="infoDefFilter"
          :items="infoDefinitions"
          label="Missing info"
          item-text="full_descriptor"
          clearable
          item-value="id"
          @change="filterChange"
        >
          <template #selection="{ item }">
            <span v-if="item.header_name" class="mr-2">{{
              item.header_name
            }}</span>
            <span class="purple-icon mr-2">{{ item.topic }}</span> ->
            <span class="cyan-icon ml-2">{{ item.sub_topic }}</span>
          </template>
          <template
            #item="{ item }"
            class="listing-picker purple-icon mb-1 bb-1 pb-1"
          >
            <span class="purple-icon">{{ item.topic }}</span> ->
            <span class="cyan-icon">{{ item.sub_topic }}</span>
            <span v-if="item.header_name" class="ml-2">
              "{{ item.header_name }}"</span
            >
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="6" sm="2">
        <v-checkbox
          v-model="showUnits"
          label="Show units"
          color="indigo"
          hide-details
          @change="filterChange"
        />
      </v-col>
      <v-col cols="6" sm="2">
        <v-checkbox
          v-model="onlyPool"
          label="Pool only"
          color="indigo"
          hide-details
          @change="filterChange"
        />
      </v-col>
      <v-col cols="6" sm="2">
        <v-checkbox
          v-model="pmManaged"
          label="Managed"
          color="indigo"
          hide-details
          @change="filterChange"
        />
      </v-col>
      <v-col cols="6" sm="1">
        <v-checkbox
          v-model="onlySetups"
          label="Setups"
          color="indigo"
          hide-details
          @change="filterChange"
        />
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      height="900"
      style="max-width: 100%"
      fixed-header
      :page="page"
      :footer-props="{
        disableItemsPerPage: true,
        itemsPerPageOptions: [30],
        disablePagination: true,
      }"
      :server-items-length="pagination.properties_count"
      class="elevation-1"
    >
      <template #top>
        <v-btn
          v-if="managementRoles"
          small
          color="success"
          @click="filterChange({ asExcel: true })"
          ><v-icon left>fas fa-table</v-icon>Export
        </v-btn>
        <v-pagination
          v-model="page"
          :length="Math.ceil(pagination.properties_count / pagination.per_page)"
          prev-icon="chevron_left"
          next-icon="chevron_right"
          class="mt-3 mb-3 flex"
          @input="filterChange"
        />
      </template>
      <template #item="{ item }">
        <tr :key="item.id">
          <td class="min-w-350 text-sm-left">
            <v-icon
              v-if="item.listing_type === 'hotel'"
              color="primary"
              class="mr-2"
              >hotel</v-icon
            >
            <v-icon v-else color="primary" class="mr-2">house</v-icon>
            <v-icon
              v-if="item.stage === 'setup'"
              color="orange lighten-2"
              class="mr-2"
              >fas fa-truck-loading</v-icon
            >
            <span class="ml-2">
              <router-link
                style="text-decoration: none"
                class="ml-1 mr-1 purple-icon"
                :target="isMobile ? '_self' : '_blank'"
                :to="addressFor(item.id, 'edit')"
              >
                {{ item.address }}
              </router-link>
            </span>
          </td>
          <td class="text-sm-left">
            {{ item.setup_stage }}
          </td>
          <td class="text-sm-left">{{ item.beds }}</td>
          <td class="text-sm-left">{{ item.baths }}</td>
          <td class="text-sm-left">
            <v-icon :color="item.has_pool ? 'primary' : 'warning'">{{
              item.has_pool ? 'fas fa-check-circle' : 'fas fa-times-circle'
            }}</v-icon>
          </td>
          <td class="text-sm-left">
            <v-icon :color="item.has_hot_tub ? 'primary' : 'warning'">{{
              item.has_hot_tub ? 'fas fa-check-circle' : 'fas fa-times-circle'
            }}</v-icon>
          </td>
          <td class="text-sm-left">
            <div v-if="item.base_rules && item.base_rules.pool">
              <v-chip>
                {{ nameById(item.base_rules.pool.assigned_contractor_id) }}
              </v-chip>
            </div>
            <div v-else>
              <v-icon color="warning">fas fa-times-circle</v-icon>
            </div>
          </td>
          <td>
            <div v-if="item.base_rules && item.base_rules.landscape">
              <v-chip>
                {{ nameById(item.base_rules.landscape.assigned_contractor_id) }}
              </v-chip>
            </div>
            <div v-else>
              <v-icon color="warning">fas fa-times-circle</v-icon>
            </div>
          </td>
          <td>
            <div v-if="item.base_rules && item.base_rules.pest">
              <v-chip>
                {{ nameById(item.base_rules.pest.assigned_contractor_id) }}
              </v-chip>
            </div>
            <div v-else>
              <v-icon color="warning">fas fa-times-circle</v-icon>
            </div>
          </td>
          <td>
            <div v-if="item.base_rules && item.base_rules.security">
              <v-chip>
                {{ nameById(item.base_rules.security.assigned_contractor_id) }}
              </v-chip>
            </div>
            <div v-else>
              <v-icon color="warning">fas fa-times-circle</v-icon>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="page"
      :length="Math.ceil(pagination.properties_count / pagination.per_page)"
      prev-icon="chevron_left"
      next-icon="chevron_right"
      class="mt-3 mb-3 flex"
      @input="filterChange"
    />
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import { mapState } from 'vuex'

export default {
  components: {},
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin],
  props: ['items', 'getProperties'],
  data: () => ({
    infoDefFilter: null,
    page: 1,
    onlySetups: false,
    onlyPool: false,
    showUnits: false,
    pmManaged: true,
    cityFilter: [],
    dialog: false,
  }),
  mounted() {
    this.filterChange()
  },
  computed: {
    ...mapState('listings', ['pagination']),
    infoDefinitions() {
      return this.$store.state.listingInfoDef.requiredInfoDefs
    },
    headers() {
      let heads = [
        { text: 'address', sortable: false },
        { text: 'stage', sortable: false },
        { text: 'beds', sortable: false },
        { text: 'baths', sortable: false },
        { text: 'pool', sortable: false },
        { text: 'jacuzzi', sortable: false },
        { text: 'pool rule', sortable: false },
        { text: 'landscape rule', sortable: false },
        { text: 'pest rule', sortable: false },
        { text: 'security rule', sortable: false },
      ]
      return heads
    },
  },
  methods: {
    filterChange(options = {}) {
      const filters = {}
      if (this.cityFilter && this.cityFilter.length) {
        filters.cityFilter = this.cityFilter
      }
      if (this.infoDefFilter) {
        filters.infoDefFilter = this.infoDefFilter
      }
      if (this.onlyPool) {
        filters.onlyPool = this.onlyPool
      }
      if (this.showUnits) {
        filters.showUnits = this.showUnits
      }
      if (this.onlySetups) {
        filters.onlySetups = this.onlySetups
      }
      if (this.pmManaged) {
        filters.pmManaged = this.pmManaged
      }
      filters.page = this.page
      this.getProperties({ ...filters, ...options })
    },
    setInfoItem(item) {
      this.$store.commit('updateChosenInfoItem', item)
    },
    availColor(text) {
      if (text === 'Check-in and Checkout today') {
        return 'orange--text font-light'
      } else if (text === 'Check-in today') {
        return 'purple--text font-light'
      } else if (text === 'Checkout today') {
        return 'dark-yellow font-light'
      } else if (text === 'Free today') {
        return 'green--text font-light'
      }
    },
    scoreClass: function (score) {
      if (score > 80) {
        return 'green-health'
      } else if (score > 60) {
        return 'yellow-health'
      } else {
        return 'red-health'
      }
    },
    addressFor(id, action) {
      if (action === 'edit') {
        return { path: `/dashboard/edit/${id}` }
      } else {
        return { path: `/dashboard/property/${id}` }
      }
    },
    score(item) {
      return item.yearly_stats.health
    },
  },
}
</script>

<style scoped>
table > tbody > tr > td:nth-child(1),
table > thead > tr > th:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 1;
  background: white;
}
table > thead > tr > th:nth-child(1) {
  z-index: 1;
}
</style>
