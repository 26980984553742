<template>
  <tr>
    <td class="text-center">
      <a class="text-decoration-underline" @click="updateBookingEngine(item)">{{
        item.name
      }}</a>
    </td>
    <td class="text-center justify-center">
      <v-col>
        <div v-if="item.whitelist.length === 0">
          <tag color="primary" :inverted="true" size="sm">All Listings</tag>
        </div>
        <div v-else>
          <tag color="primary" :inverted="true" size="sm"
            >{{ item.whitelist.length }} listings</tag
          >
        </div>
      </v-col>
      <v-col v-if="item.blacklist.length !== 0">
        <div>
          <tag color="secondary" :inverted="true" size="sm"
            >{{ item.blacklist.length }} Exluded</tag
          >
        </div></v-col
      >
    </td>
    <td class="text-center">
      <confirmation-modal
        :text="`Are you sure you want to ${
          isActive ? 'deactivate' : 'activate'
        } this booking site?`"
        :title="`${item.name} booking site`"
        @action="itemChange"
      >
        <template #activator="{ on }">
          <v-switch readonly :input-value="isActive" v-on="on" />
        </template>
      </confirmation-modal>
    </td>
  </tr>
</template>

<script>
import axios from 'axios'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import Tag from 'components/common/tag.vue'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'

export default {
  components: { ConfirmationModal, Tag },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['item', 'afterChange', 'listings'],
  data() {
    return {
      singleSelect: false,
      menu: false,
      menu2: false,
      isActive: this.item.active,
    }
  },
  methods: {
    updateBookingEngine(item) {
      if (!item.sections.header) {
        item.sections.header = {
          bg_image: null,
        }
      }
      if (!item.sections.favorites) {
        item.sections.favorites = {}
      }
      if (!item.sections.footer) {
        item.sections.footer = {}
      }
      if (!item.theme.main_color) {
        item.theme.main_color = '#44a2a2'
      }
      if (!item.theme.secondary_color) {
        item.theme.secondary_color = '#8F90A6'
      }
      if (!item.theme.actions_color) {
        item.theme.actions_color = '#464673'
      }
      this.$store.commit('showModal', {
        name: 'BookingEngineModal',
        props: {
          editMode: true,
          afterSaveFunc: this.afterChange,
          currentBookingEngineSetting: Object.assign({}, item),
          listings: this.listings,
        },
        isPersistent: false,
      })
    },
    deleteBookingEngine() {
      const userConfirm = confirm('Are you sure you want to delete?')
      if (userConfirm) {
        axios
          .delete(`/api/booking-engine-settings/${this.item.id}`)
          .then(() => {
            this.$store.commit('updateListingLoading', false)
            if (this.afterChange) {
              this.afterChange()
            } else {
              this.$store.dispatch('getBookingEngineSettings', {
                id: this.listingManageContext
                  ? this.$router.currentRoute.path.split('/')[3]
                  : 'All',
              })
            }
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    itemChange() {
      this.$store.commit('updateListingLoading', true)
      axios
        .patch(`/api/booking-engine-settings/${this.item.id}`, {
          active: !this.isActive,
        })
        .then(() => {
          this.$store.commit('updateListingLoading', false)
          this.isActive = !this.isActive
          if (this.afterChange) {
            this.afterChange()
          }
        })
        .catch(error => {
          console.error(error)
        })
    },
  },
}
</script>
