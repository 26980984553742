<template>
  <v-dialog
    :value="value"
    width="90%"
    @click:outside="$emit('update:value', false)"
  >
    <v-card :loading="guestLoading">
      <div>
        <v-card-title class="h1 py-2 px-4">Guest Details</v-card-title>
        <v-divider></v-divider>
        <v-row class="ma-0">
          <v-col cols="5" class="pa-4">
            <div class="d-flex align-center mx-0">
              <div class="me-2">
                <v-avatar size="45" color="light400">
                  <span class="black--text font-weight-medium">{{
                    initialsFromFullName(guest.full_name)
                  }}</span>
                </v-avatar>
              </div>
              <div class="">
                <div class="text-h6 font-weight-medium d-flex align-center">
                  {{ guest.full_name }}
                  <div
                    v-for="tag in guest.extra_data?.user_tags"
                    :key="tag"
                    class="text-capitalize font-weight-medium py-0 px-2 rounded-pill text-caption black950--text ms-2 d-flex align-center"
                    :style="`background-color: ${randomColors(tag)}1A;`"
                  >
                    <div
                      class="dot me-1"
                      :style="`background-color: ${randomColors(tag)};`"
                    />
                    {{ tag }}
                  </div>
                </div>
                <div class="dark500--text text-caption font-weight-medium">
                  {{ guest.primary_email }}
                </div>
              </div>
            </div>
            <div class="mt-6">
              <v-card
                outlined
                class="d-flex justify-space-around pa-3 rounded-lg"
              >
                <template v-for="(kpi, i) in kpis">
                  <div :key="kpi.value">
                    <div class="text-body-2 dark500--text text-uppercase">
                      {{ kpi.title }}
                    </div>
                    <div class="text-h6 black950--text font-weight-semibold">
                      <span v-if="kpi.currency">{{
                        toMoney(get(kpi.value, stats))
                      }}</span>
                      <span v-else>{{ get(kpi.value, stats) || '-' }}</span>
                    </div>
                  </div>
                  <v-divider
                    v-if="i < kpis.length - 1"
                    :key="kpi.title"
                    vertical
                  ></v-divider>
                </template>
              </v-card>
            </div>
            <v-form ref="guestForm">
              <div
                class="my-6 text-body-2 font-weight-medium d-flex justify-space-between"
              >
                <div>Details</div>
                <div>
                  <v-btn
                    v-if="editMode"
                    x-small
                    color="info"
                    class="initial-border ms-2"
                    text
                    @click="onEdit"
                    >Cancel</v-btn
                  >
                  <v-btn
                    v-if="editMode"
                    x-small
                    depressed
                    color="info"
                    class="initial-border ms-2"
                    @click="onGuestUpdate"
                    >Save</v-btn
                  >
                  <v-btn
                    v-if="!editMode"
                    outlined
                    x-small
                    color="info"
                    class="initial-border ms-2"
                    text
                    @click="onEdit"
                    >Edit</v-btn
                  >
                </div>
              </div>
              <div v-for="detail in details" :key="detail.value">
                <div
                  class="d-flex justify-space-between align-baseline text-body-2 my-2"
                  :class="{
                    'mb-0': editMode && detail.value == 'primary_email',
                  }"
                >
                  <div class="text--secondary">{{ detail.title }}</div>
                  <div v-if="detail.tags">
                    <v-chip
                      v-for="tag in get(detail.value, guest)"
                      :key="tag"
                      label
                      small
                      outlined
                      class="ms-2"
                    >
                      {{ tag }}</v-chip
                    >
                  </div>
                  <div
                    v-else
                    class="font-weight-medium"
                    @submit.prevent="onGuestUpdate"
                  >
                    <phone-input
                      v-if="editMode && detail.value == 'primary_phone'"
                      v-model="guestEdit[detail.value]"
                      hide-details
                    />
                    <v-text-field
                      v-else-if="editMode && detail.value == 'primary_email'"
                      v-model="guestEdit[detail.value]"
                      color="info"
                      :rules="emailRules"
                      class="text-body-2 font-weight-medium info--text"
                      :placeholder="detail.title"
                      dense
                    />
                    <v-text-field
                      v-else-if="editMode && detail.editable"
                      v-model="guestEdit[detail.value]"
                      color="info"
                      hide-details
                      class="text-body-2 font-weight-medium info--text"
                      :placeholder="detail.title"
                      dense
                    />
                    <span v-else>
                      {{ get(detail.value, guest) || '-' }}
                    </span>
                  </div>
                </div>
                <v-divider></v-divider>
              </div>
            </v-form>
            <div>
              <div class="my-6 text-body-2 font-weight-medium">
                Notes <span>({{ notesList.length }})</span>
              </div>
              <textarea
                v-model="newNote"
                outlined
                class="w-100 rounded-lg py-2 px-3 text-body-2 thin-border w-100"
                rows="1"
                placeholder="Add a note"
                @keydown.enter.prevent="updateGuestNotes"
              ></textarea>
              <div class="mt-6 notes-container">
                <div v-for="note in notesList" :key="note.date">
                  <div
                    class="d-flex justify-space-between text-body-2 ma-2 me-0"
                  >
                    <div class="font-weight-medium">{{ note.msg }}</div>
                    <div
                      class="font-weight-medium text-caption text--secondary"
                    >
                      {{ parseDate(note.date, 'MMM DD, YYYY') }}
                      <v-btn
                        class="ms-2"
                        x-small
                        color="grey"
                        icon
                        @click="removeGuestNote(note.date)"
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <v-divider></v-divider>
                </div>
              </div>
            </div>
          </v-col>
          <v-col class="pa-0 ma-0 d-flex">
            <v-divider vertical class="min-margin"></v-divider>
            <div class="w-100">
              <v-tabs
                v-model="guestTab"
                background-color="white"
                slider-color="primary500"
              >
                <v-tab
                  href="#reservations"
                  class="text-capitalize font-weight-medium tab-blue"
                >
                  Reservations</v-tab
                >
                <v-tab
                  href="#reviews"
                  disabled
                  class="text-capitalize font-weight-medium tab-blue"
                  >Reviews</v-tab
                >
                <v-tab
                  href="#behaviors"
                  disabled
                  class="text-capitalize font-weight-medium tab-blue"
                >
                  Behavior Patterns</v-tab
                >
                <v-tab
                  href="#notes"
                  disabled
                  class="text-capitalize font-weight-medium tab-blue"
                >
                  Notes</v-tab
                >
              </v-tabs>
              <v-divider />
              <v-tabs-items v-model="guestTab">
                <v-tab-item value="reservations">
                  <div class="pa-4">
                    <div class="text-body-2 font-weight-medium">
                      Last Reservation
                    </div>
                    <v-card
                      v-if="lastReservation"
                      outlined
                      class="rounded-lg my-4"
                    >
                      <div
                        class="d-flex justify-space-between align-center pa-3"
                      >
                        <div>
                          <div
                            class="rounded-pill light300 text-caption d-inline-block px-2 my-2"
                          >
                            #{{ lastReservation.confirmation_code }}
                          </div>
                          <div class="text-body-2 font-weight-medium">
                            {{ lastReservation.listing.nickname }}
                            <span class="font-weight-light ms-1">{{
                              lastReservation.listing.address
                            }}</span>
                          </div>
                        </div>
                        <div
                          class="text-capitalize font-weight-semibold py-1 px-4 rounded-pill text-caption"
                          :style="`background-color: ${
                            STATUS_COLORS[lastReservation.status]?.background
                          }; color: ${
                            STATUS_COLORS[lastReservation.status]?.text
                          };`"
                        >
                          {{ lastReservation.status }}
                        </div>
                      </div>
                      <v-divider />
                      <div class="px-3 d-flex justify-space-between">
                        <div class="py-3 pe-3 text-no-wrap">
                          <div class="dark600--text text-caption">
                            Reservation Dates
                          </div>
                          <div class="black950--text font-weight-medium">
                            {{ formattedReservationDates }}
                          </div>
                        </div>
                        <v-divider vertical />
                        <div class="pa-3">
                          <div class="dark600--text text-caption">Value</div>
                          <div class="black950--text font-weight-medium">
                            {{ toMoney(lastReservation.fare_accommodation) }}
                          </div>
                        </div>
                        <v-divider vertical />
                        <div class="pa-3">
                          <div class="dark600--text text-caption">Requests</div>
                          <div class="black950--text font-weight-medium">
                            <v-chip
                              v-for="tag in Object.keys(
                                lastReservation.special_requests
                              )"
                              :key="tag"
                              label
                              small
                              outlined
                              class="me-2 mb-1"
                            >
                              {{ tag }}</v-chip
                            >
                            <span
                              v-if="
                                !Object.keys(lastReservation.special_requests)
                                  .length
                              "
                              >-</span
                            >
                          </div>
                        </div>
                        <v-divider vertical />
                        <div v-if="lastReservation.reviews.length" class="pa-3">
                          <div class="dark600--text text-caption">Review</div>
                          <div
                            class="black950--text font-weight-medium d-flex justify-center"
                          >
                            <v-icon
                              v-for="s in fullStarsCount(
                                lastReservation.reviews[0]
                              )"
                              :key="s"
                              class="mr-2"
                              small
                              color="yellow"
                              >fas fa-star</v-icon
                            >
                            <v-icon
                              v-if="hasHalfStar(lastReservation.reviews[0])"
                              small
                              color="yellow darken-2"
                              >fas fa-star-half</v-icon
                            >
                            {{ roundedStars(lastReservation.reviews[0]) }}
                          </div>
                        </div>
                      </div>
                    </v-card>
                    <div class="text-body-2 font-weight-medium mt-8">
                      All Reservations
                    </div>
                    <v-simple-table class="mb-4">
                      <thead>
                        <tr>
                          <th class="text-left">Property Name</th>
                          <th class="text-left">Amount</th>
                          <th class="text-left">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="reservation in guest.reservations"
                          :key="reservation.confirmation_code"
                        >
                          <!-- Property Name -->
                          <td>{{ reservation.listing.nickname }}</td>

                          <!-- Amount -->
                          <td>{{ toMoney(reservation.fare_accommodation) }}</td>

                          <!-- Date (Check-in to Check-out) -->
                          <td>{{ formatReservationDates(reservation) }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </div>
                </v-tab-item>
                <v-tab-item value="reviews"> reviews </v-tab-item>
              </v-tabs-items>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import FormattersMixin from 'components/mixins/formatters-mixin'
import PhoneInput from 'components/common/phone-input.vue'
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import { get } from 'lodash/fp'
import maxBy from 'lodash/maxBy'
import { STATUS_COLORS } from '@/consts'

const details = [
  {
    title: 'Full Name',
    value: 'full_name',
    editable: true,
  },
  {
    title: 'Phone Number',
    value: 'primary_phone',
    editable: true,
  },
  {
    title: 'Email',
    value: 'primary_email',
    editable: true,
  },
  {
    title: 'Location',
    value: 'reservations[0].pre_check_in.guests_details[0].address',
  },
  {
    title: 'Birthday',
    value: 'extra_data.birthday',
  },
  {
    title: 'Booking Frequency',
    value: '',
  },
  {
    title: 'Tags',
    value: 'extra_data.profile_tags',
    tags: true,
  },
]
const INITIAL_STATS = {
  avgReviewScore: 0,
  totalRevenue: 0,
  upcomingVisits: 0,
}

export default {
  name: 'GuestModal',
  mixins: [CommonFunctions, FormattersMixin, FormRulesMixin],
  components: { PhoneInput },
  props: ['guestId', 'value'],
  data() {
    return {
      STATUS_COLORS,
      editMode: false,
      guestEdit: {},
      notes: {},
      newNote: '',
      guest: {},
      stats: INITIAL_STATS,
      guestLoading: false,
      guestTab: 'reservations',
      details,
      kpis: [
        {
          title: 'Satisf. Score',
          value: 'avgReviewScore',
        },
        {
          title: 'Total revenue',
          value: 'totalRevenue',
          currency: true,
        },
        {
          title: 'Upcoming visits',
          value: 'upcomingVisits',
        },
      ],
    }
  },
  mounted() {
    if (this.guestId) this.fetchGuest()
  },
  computed: {
    initialStats() {
      return {
        avgReviewScore: 0,
        totalRevenue: 0,
        upcomingVisits: 0,
      }
    },
    guestColor() {
      return this.randomColors(this.guest.full_name)
    },
    lastReservation() {
      return maxBy(this.guest.reservations, 'check_out')
    },
    formattedReservationDates() {
      const { check_in, check_out } = this.lastReservation
      const checkIn = this.$moment(check_in).format('MMM DD, YYYY')
      const checkOut = this.$moment(check_out).format('MMM DD, YYYY')
      return `${checkIn} - ${checkOut}`
    },
    notesList() {
      return Object.keys(this.notes).map(key => ({
        date: key,
        msg: this.notes[key],
      }))
    },
  },
  methods: {
    get,
    async fetchGuest() {
      this.guestLoading = true
      this.guest = await this.$store.dispatch('guests/fetchGuest', this.guestId)
      this.notes = this.guest.notes
      this.calculateStats()
      this.guestLoading = false
    },
    fullStarsCount(review) {
      return Math.floor(review.normilized_scrore_overall / 2)
    },
    hasHalfStar(review) {
      return (review.normilized_scrore_overall / 2) % 1 !== 0
    },
    roundedStars(review) {
      return Math.round(((review.normilized_scrore_overall / 2) * 10) / 10)
    },
    async updateGuestNotes() {
      await this.$store.dispatch('guests/updateGuestNotes', {
        id: this.guestId,
        notes: { ...this.guest.notes, [this.$moment().format()]: this.newNote },
      })
      this.newNote = ''
      this.fetchGuest()
    },
    async removeGuestNote(key) {
      delete this.guest.notes[key]
      await this.$store.dispatch('guests/updateGuestNotes', {
        id: this.guestId,
        notes: this.guest.notes,
      })
      this.fetchGuest()
    },
    formatReservationDates(reservation) {
      const { check_in, check_out } = reservation
      const checkIn = this.$moment(check_in).format('MMM DD, YYYY')
      const checkOut = this.$moment(check_out).format('MMM DD, YYYY')
      return `${checkIn} - ${checkOut}`
    },
    calculateStats() {
      this.stats = { ...INITIAL_STATS }
      this.guest.reservations.forEach(r => {
        this.stats.upcomingVisits +=
          r.check_in > this.$moment().format('YYYY-MM-DD')
        this.stats.totalRevenue += r.fare_accommodation
        const rawScore = r.reviews.reduce((acc, review) => {
          return acc + review.normilized_scrore_overall
        }, 0)
        this.stats.avgReviewScore =
          Math.round((rawScore / r.reviews.length / 2) * 10) / 10
      })
    },
    onEdit() {
      this.editMode = !this.editMode
      this.guestEdit = { ...this.guest }
    },
    async onGuestUpdate() {
      if (!this.$refs.guestForm.validate()) return
      this.guestLoading = true
      await this.$store.dispatch('guests/updateGuest', {
        id: this.guestEdit.id,
        full_name: this.guestEdit.full_name,
        email: this.guestEdit.primary_email,
        phone: this.guestEdit.primary_phone,
      })
      this.fetchGuest()
      this.editMode = false
    },
  },
}
</script>

<style scoped>
.min-margin {
  margin: 0.1px;
}
:deep() .v-tabs-bar {
  height: 40px;
}
.thin-border {
  border: thin solid rgba(0, 0, 0, 0.12);
}
.thin-border:focus {
  outline: none;
}
.notes-container {
  overflow-y: auto;
  max-height: 150px;
}
.initial-border {
  border-color: initial !important;
}
:deep() .phone-input {
  text-align: end;
}
:deep() .v-text-field__slot > input {
  text-align: end;
}
:deep() .v-input__slot:before,
.v-input__slot:after {
  display: none;
}
:deep() .v-input__slot .vue-tel-input {
  border: none;
  font-size: 0.875rem;
}
:deep() .v-input__slot .vue-tel-input > input {
  color: unset !important;
}
</style>
