import axios from '@/axios/config'

export default {
  namespaced: true,
  state: {
    voice: {
      dialog: false,
      phone: null,
      name: null,
    },
  },
  mutations: {
    updateVoiceModal(state, payload) {
      state.voice = { ...state.voice, ...payload }
    },
  },
  actions: {
    async syncTemplates(_, { tenantId, ...body }) {
      return axios.post(`/api/twilio/templates/${tenantId}/sync`, body)
    },
    async generateInfo(_, body) {
      return axios.post(`/api/conversations/generate-train-data`, body)
    },
    async detectInfo(_, body) {
      return axios.post(`/api/conversations/detect-train-data`, body)
    },
    async createPolicy(_, body) {
      return axios.post(`/api/policies`, body)
    },
    async updatePolicy(_, body) {
      return axios.put(`/api/policies/${body.id}`, body)
    },
    async createInfoItem(_, body) {
      return axios.post(`/api/listing-info-items/create-by-trained-data`, body)
    },
    async generateVoiceToken(_) {
      return axios.get(`/api/twilio/voice-token`)
    },
    async getCallerInfo(_, params) {
      return axios.get(`/api/twilio/caller-info/`, { params })
    },
    async getCallLogs(_, params) {
      return axios.get(`/api/call-logs/`, { params })
    },
  },
}
