<template>
  <v-card class="pa-4" style="border-radius: 10px">
    <div class="d-flex pa-4">
      <div
        class="flex text-center justify-center font-weight-medium text-subtitle-1"
      >
        {{ title }}
      </div>
    </div>
    <v-form ref="form">
      <v-text-field
        v-model.trim="formData.name"
        dense
        outlined
        :rules="[required]"
        :label="$t('Type')"
      />
      <v-combobox
        v-model="formData.category"
        :items="categories"
        dense
        outlined
        :rules="[required]"
        :label="$t('Category')"
      />
      <v-select
        v-if="!isEditMode"
        v-model="formData.expense_role"
        label="Expense role"
        outlined
        :rules="[required]"
        :items="expenseRoles"
        item-text="name"
        item-value="value"
        dense
      />
      <v-text-field
        v-model="formData.amount"
        type="number"
        outlined
        dense
        :label="$t('Amount')"
        required
      />
      <v-select
        v-model="formData.units"
        outlined
        dense
        :items="expenseUnits"
        label="Unit"
        :rules="unitsRules"
      />
      <v-select
        v-if="formData.units === 'percentage'"
        v-model="formData.quantifier"
        outlined
        dense
        :items="quantifier"
        :rules="quantifierRules"
        label="Quantifier"
      />
      <v-card-actions class="justify-end">
        <v-btn color="primary" outlined @click="onClose">Cancel</v-btn>
        <v-btn color="primary" @click="submit">Submit</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ExpenseTypeModal',
  mixins: [FormRulesMixin, FormattersMixin],
  props: ['categories', 'expenseRoles', 'afterSave', 'expenseType'],
  data: function () {
    return {
      formData: this.expenseType || {},
    }
  },
  computed: {
    ...mapState({
      expenseSettings: state => state.expenseTypes.expenseSettings,
    }),
    title() {
      return this.isEditMode
        ? this.$t('Edit Expense Type')
        : this.$t('New Expense Type')
    },
    isEditMode() {
      return !!this.expenseType
    },
    quantifier() {
      return this.expenseSettings
        ? Object.entries(this.expenseSettings).map(([key, value]) => ({
            text: value,
            value: key,
          }))
        : []
    },
    expenseUnits() {
      return [
        { text: this.currencySign, value: 'fixed' },
        { text: '%', value: 'percentage' },
      ]
    },
    unitsRules() {
      return [
        v => {
          if (this.formData.amount !== '' && this.formData.amount !== null) {
            return v ? true : 'Unit is required when amount is specified'
          }
          return true
        },
      ]
    },
    quantifierRules() {
      return [
        v => {
          if (
            this.formData.units === 'percentage' &&
            !this.formData.quantifier
          ) {
            return 'Quantifier is required when unit is percentage'
          }
          return true
        },
      ]
    },
  },
  methods: {
    ...mapActions('expenseTypes', [
      'createNewExpenseType',
      'updateExpenseType',
    ]),
    submit() {
      if (this.$refs.form.validate()) {
        const action = this.isEditMode
          ? 'updateExpenseType'
          : 'createNewExpenseType'
        this[action](this.formData).then(() => {
          this.afterSave()
          this.onClose()
        })
      }
    },
    onClose() {
      this.$store.commit('hideModal')
    },
  },
}
</script>

<style scoped></style>
