<template>
  <div v-if="$store.state.autoListingTaskRules" class="mt-3">
    <auto-listing-task-rule-multi-edit
      v-if="isPropertyManager || isAdmin"
      :hide="changeMutliEdit"
      :show-multi-edit="showMultiEdit"
      :after-save-func="afterSave"
    />
    <div class="d-flex justify-space-between">
      <h3>{{ $t('Service calls rules') }}</h3>
      <div>
        <v-btn
          :disabled="!someRulesSelected"
          outlined
          info
          color="primary"
          class="mx-2"
          @click="showMultiEdit = true"
          >Edit selected</v-btn
        >
        <v-btn v-if="!viewMode" color="primary" @click="addRule">
          <v-icon left>fas fa-plus</v-icon>
          New Rule</v-btn
        >
      </div>
    </div>
    <div>
      <v-btn
        color="primary"
        class="white--text"
        depressed
        @click="showFilters = !showFilters"
      >
        <v-icon :class="showFilters ? 'fa-rotate-90' : ''" left small
          >$filters
        </v-icon>
        Filters
      </v-btn>
      <v-slide-y-transition>
        <v-row v-if="!loading && !viewMode && generalMode && showFilters">
          <v-col cols="auto">
            <v-text-field
              v-model="searchTerm"
              dense
              solo-inverted
              flat
              outlined
              :label="$t('Search by description')"
              append-icon="search"
              hide-details
            />
          </v-col>
          <v-col cols="auto">
            <contractor-select
              :allow-multiple="true"
              :filter-change="contractorFilterChange"
            />
          </v-col>
          <v-col cols="auto">
            <multi-property-picker
              v-if="!loading"
              v-model="listingsIds"
              :hide-btns="true"
              :filter-change="listingChange"
            />
          </v-col>
          <!--          <v-col cols="auto">-->
          <!--            <v-select-->
          <!--              v-model="taskTypeFilter"-->
          <!--              :items="listingTaskTypes"-->
          <!--              dense-->
          <!--              outlined-->
          <!--              :label="$t('Type')"-->
          <!--              @change="taskTypeFilterChange($event)"-->
          <!--            />-->
          <!--          </v-col>-->
          <!--          <v-col cols="auto">-->
          <!--            <v-select-->
          <!--              v-model="subTopicFilter"-->
          <!--              :disabled="!taskTypeFilter"-->
          <!--              :items="subTopics(taskTypeFilter)"-->
          <!--              :label="$t('Sub type')"-->
          <!--              dense-->
          <!--              outlined-->
          <!--              @change="subTopicFilterChange($event)"-->
          <!--            />-->
          <!--          </v-col>-->
          <v-col cols="auto">
            <v-select
              v-model="regionFilter"
              :label="$t('Regions')"
              outlined
              hide-details
              dense
              clearable
              :items="listingRegions"
              small-chips
              @change="regionChanged($event)"
            />
          </v-col>
        </v-row>
      </v-slide-y-transition>
    </div>
    <v-pagination
      v-if="pagination"
      v-model="page"
      :length="Math.ceil(pagination.totalItems / pagination.rowsPerPage)"
      prev-icon="chevron_left"
      next-icon="chevron_right"
      class="mt-3 mb-3"
    />
    <v-data-table
      :loading="listingLoading"
      :headers="headers"
      show-select
      checkbox-color="primary"
      :items="filteredItems"
      :items-per-page="100"
      class="pb-2"
      @toggle-select-all="selectAll"
    >
      <template #top>
        <div class="d-flex flex-row-reverse">
          <v-switch
            v-model="activeOnly"
            dense
            hide-details
            class="ml-4 px-3 mt-0"
            :label="$t('Active only')"
            @change="activeOnlyChange($event)"
          />
          <v-switch
            v-model="manualOnly"
            dense
            hide-details
            class="ml-4 px-3 mt-0"
            :label="$t('Manual only')"
            @change="manualOnlyChange($event)"
          />
          <v-switch
            v-model="masterOnly"
            dense
            hide-details
            class="ml-4 px-3 mt-0"
            :label="$t('Master only')"
            @change="masterOnlyChange($event)"
          />
          <v-switch
            v-model="companyOnly"
            dense
            hide-details
            class="ml-4 px-3 mt-0"
            :label="$t('Company only')"
            @change="companyOnlyChange($event)"
          />
        </div>
      </template>
      <template #item="{ item }">
        <auto-listing-task-rule-row
          v-if="!viewMode || item.active"
          :key="item.id"
          :after-change="afterSave"
          :view-mode="viewMode"
          :item="item"
          @open-rules="getMasterRulesChildren(item.id)"
        />
      </template>
    </v-data-table>
    <v-dialog
      v-model="isMasterRulesDialog"
      max-width="800"
      @click:outside="closeMasterRulesDialog"
    >
      <v-card class="pb-4">
        <v-card-title class="text-h6 mb-2"> Listings </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="listingsSearchTerm"
            label="Search by listing name"
            outlined
            dense
          />
          <v-list dense>
            <v-list-item v-for="item in sortedListings" :key="item.id">
              <v-alert
                :type="isRuleAppliedToListing(item.id) ? 'success' : null"
                width="100%"
                dense
                text
                >{{ item.nickname }}</v-alert
              >
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import AutoListingTaskRuleRow from './auto-listing-task-rule-row.vue'

import ContractorSelect from './contractor-select.vue'
import MultiPropertyPicker from './multi-property-picker.vue'
import sortBy from 'lodash/sortBy'
import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import AutoListingTaskRuleMultiEdit from './auto-listing-task-rules/auto-listing-task-rule-mutli-edit'
import { mapGetters, mapState } from 'vuex'
import get from 'lodash/fp/get'

export default {
  components: {
    AutoListingTaskRuleMultiEdit,
    AutoListingTaskRuleRow,
    MultiPropertyPicker,
    ContractorSelect,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['viewMode', 'generalMode'],
  data: function () {
    return {
      showFilters: false,
      showMultiEdit: false,
      companyOnly: false,
      taskTypeFilter: null,
      subTopicFilter: null,
      regionFilter: null,
      contractorID: [],
      listingsIds: [],
      searchTerm: '',
      masterOnly: false,
      activeOnly: true,
      manualOnly: false,
      page: 1,
      isMasterRulesDialog: false,
      rulesIds: [],
      listingsSearchTerm: '',
    }
  },
  watch: {
    page(val) {
      this.page = val
      this.getAutoListingTaskRules()
    },
    searchTerm: {
      handler: function (val) {
        this.page = 1
        this.searchTerm = val
        this.debouncer.call(this)
      },
      deep: true,
    },
  },
  mounted() {
    this.getAutoListingTaskRules()
    this.debouncer = debounce(this.getAutoListingTaskRules, 750)
  },
  beforeDestroy() {
    this.$store.commit('updateLtdSelectedIDS', new Set())
  },
  methods: {
    addRule() {
      this.$store.commit('showModal', {
        name: 'AutoListingTaskRuleModal',
        props: {
          editMode: false,
          afterSaveFunc: this.afterSave,
          listingId: this.$router.currentRoute.path.split('/')[3],
        },
      })
    },
    selectAll() {
      this.filteredItems.forEach(task => {
        this.addAutoRuleSelected(task)
      })
    },
    UnSelectAll() {
      this.filteredItems.forEach(task => {
        if (task.selected) {
          this.addAutoRuleSelected(task)
        }
      })
    },
    afterSave() {
      this.getAutoListingTaskRules()
      if (this.showMultiEdit) {
        this.showMultiEdit = false
      }
    },
    subTopicFilterChange(val) {
      this.page = 1
      this.subTopicFilter = val
      this.getAutoListingTaskRules()
    },
    regionChanged(val) {
      this.page = 1
      this.regionFilter = val
      this.getAutoListingTaskRules()
    },
    taskTypeFilterChange(val) {
      this.page = 1
      this.taskTypeFilter = val
      this.getAutoListingTaskRules()
    },
    masterOnlyChange(val) {
      this.page = 1
      this.masterOnly = val
      this.getAutoListingTaskRules()
    },
    companyOnlyChange(val) {
      this.page = 1
      this.companyOnly = val
      this.getAutoListingTaskRules()
    },
    manualOnlyChange(val) {
      this.page = 1
      this.manualOnly = val
      this.getAutoListingTaskRules()
    },
    activeOnlyChange(val) {
      this.page = 1
      this.activeOnly = val
      this.getAutoListingTaskRules()
    },
    listingChange() {
      this.page = 1
      this.getAutoListingTaskRules()
    },
    contractorFilterChange(contractorID) {
      this.page = 1
      if (contractorID.length === 0) {
        this.contractorID = null
      } else {
        this.contractorID = contractorID
      }
      this.getAutoListingTaskRules()
    },
    getAutoListingTaskRules() {
      this.$store.dispatch('getAutoListingTaskRules', {
        search_term: this.searchTerm,
        page: this.page || 1,
        manual_only: this.manualOnly,
        listings_ids: this.listingsIds,
        task_type: this.taskTypeFilter,
        sub_topic: this.subTopicFilter,
        region_id: this.regionFilter,
        contractor_id: this.contractorID,
        only_master: this.masterOnly,
        company_only: this.companyOnly,
        id: this.listingManageContext
          ? this.$router.currentRoute.path.split('/')[3]
          : 'All',
        active_only: this.activeOnly,
      })
    },
    changeMutliEdit() {
      this.showMultiEdit = false
    },

    passSearchFilter(rule) {
      if (
        this.listingsIds &&
        this.listingsIds.length > 0 &&
        !this.listingsIds.includes(rule.listing_id)
      ) {
        return false
      }
      const personnelId = get('personnel.id', rule)
      if (
        this.contractorID &&
        this.contractorID.length > 0 &&
        !(
          this.contractorID.includes(rule.assigned_contractor_id) ||
          this.contractorID.includes(personnelId)
        )
      ) {
        return false
      }
      if (this.generalMode && this.activeOnly && !rule.active) {
        return false
      }
      if (this.generalMode && this.manualOnly && rule.created_auto) {
        return false
      }

      return true
    },
    async getMasterRulesChildren(masterRuleId) {
      const { data } = await axios.get(
        `/api/auto-listing-children/${masterRuleId}`
      )
      this.rulesIds = data.map(item => item.listing_id)
      this.isMasterRulesDialog = true
    },
    closeMasterRulesDialog() {
      this.isMasterRulesDialog = false
      setTimeout(() => {
        this.rulesIds = []
      }, 300)
    },
    isRuleAppliedToListing(id) {
      return this.rulesIds.includes(id)
    },
  },
  computed: {
    ...mapGetters(['listingsPicker']),
    ...mapState('regions', ['regions']),
    listingRegions() {
      return this.regions.map(r => ({
        text: r.name,
        value: r.id,
      }))
    },
    someRulesSelected() {
      return this.$store.state.autoRulesSelectedIDS.length > 0
    },
    pagination() {
      return this.$store.state.autoRulesPagination
    },
    headers() {
      let cols = [
        { text: 'Active', align: 'center', sortable: false },
        { text: 'Desc', align: 'center', sortable: false },
        { text: 'Per visit', align: 'center', sortable: false },
        { text: 'Repeating', align: 'center', sortable: false },
        { text: 'Task Type', align: 'center', sortable: false },
        { text: 'Assigned Contractor', align: 'center', sortable: false },
        { text: 'Created At', align: 'center', sortable: false },
        { text: 'Last Visit', align: 'center', sortable: false },
        { text: 'Planned Duration', align: 'center', sortable: false },
      ]
      if (!this.listingManageContext) {
        cols.unshift({
          text: 'Listing',
          align: 'center',
          value: 'listing_nickname',
        })
      }
      return cols
    },
    filteredItems() {
      let tasks = []
      this.$store.state.autoListingTaskRules.forEach(rule => {
        if (this.passSearchFilter(rule)) {
          tasks.push(rule)
        }
      })
      return sortBy(tasks, ['listing_nickname'])
    },
    filteredListings() {
      if (this.listingsSearchTerm) {
        return filter(this.listingsPicker, listing =>
          includes(
            listing.nickname.toLowerCase(),
            this.listingsSearchTerm.toLowerCase()
          )
        )
      }
      return this.listingsPicker
    },
    sortedListings() {
      const applied = filter(this.filteredListings, item =>
        this.rulesIds.includes(item.id)
      )
      const notApplied = filter(
        this.filteredListings,
        item => !this.rulesIds.includes(item.id)
      )
      return [...applied, ...notApplied]
    },
    items() {
      return this.list
    },
  },
}
</script>
