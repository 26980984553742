<template>
  <v-card-title class="justify-space-between pa-4">
    <v-icon v-if="isDrawerOpen" left small @click="closeDrawer"
      >$left_arrow</v-icon
    >
    <span>{{ title }}</span>
    <v-spacer />
    <slot name="actions" />
  </v-card-title>
</template>

<script>
export default {
  name: 'ConversationInfoTitle',
  props: {
    title: {
      type: String,
      default: '',
    },
    guestyId: {
      type: String,
      default: '',
    },
  },
  computed: {
    isDrawerOpen() {
      return this.$route.query.reservation && this.$vuetify.breakpoint.xs
    },
  },
  methods: {
    closeDrawer() {
      this.$router.replace({ query: null })
    },
  },
}
</script>

<style scoped></style>
