<template>
  <v-layout v-if="date" class="column white--text pa-3 info-box">
    <v-layout
      v-if="rate['status'] !== 'available'"
      wrap
      justify-center
      class="white--text"
    >
      <v-flex class="center-text text-h5">
        {{ rate['status'] }}
      </v-flex>
    </v-layout>
    <v-layout wrap class="bb-1 xs12 align-baseline">
      <v-flex v-if="rate['sold_price']" class="xs6 big-text text-h5">
        {{ dollarFormatter(rate['sold_price']) }}
      </v-flex>
      <v-flex v-if="rate['sold_price']" class="xs6 big-text mb-2">
        Sold price
      </v-flex>
      <v-flex class="xs6 big-text text-h5">
        {{ dollarFormatter(rate['price']) }}
      </v-flex>
      <v-flex class="xs6 big-text mb-2"> {{ date }} </v-flex>
    </v-layout>
    <v-layout wrap xs12 align-baseline>
      <v-flex class="mt-2 bolder" x3>
        {{ dollarFormatter(currentPricing.base_price) }}
      </v-flex>
      <v-flex xs9> Base Price </v-flex>
    </v-layout>
    <v-layout
      v-if="getFactor('time_until_booking_factor')"
      wrap
      xs12
      align-baseline
    >
      <v-flex class="mt-2" x3>
        {{
          getFactor('time_until_booking_factor').change_in_price > 0 ? '+' : ''
        }}{{ round(getFactor('time_until_booking_factor').change_in_price) }}
      </v-flex>
      <v-flex xs9>
        {{ getFactor('time_until_booking_factor').text }}
      </v-flex>
    </v-layout>

    <v-layout v-if="getFactor('velocity')" wrap xs12 align-baseline>
      <v-flex class="mt-2" x3>
        {{ getFactor('velocity').change_in_price > 0 ? '+' : ''
        }}{{ round(getFactor('velocity').change_in_price) }}
      </v-flex>
      <v-flex xs9>
        {{ getFactor('velocity').text }}
      </v-flex>
    </v-layout>

    <v-layout v-if="getFactor('health_bump')" wrap xs12 align-baseline>
      <v-flex class="mt-2" x3>
        {{ getFactor('health_bump').change_in_price > 0 ? '+' : ''
        }}{{ round(getFactor('health_bump').change_in_price) }}
      </v-flex>
      <v-flex xs9>
        {{ getFactor('health_bump').text }}
      </v-flex>
    </v-layout>

    <v-layout v-if="getFactor('seasonality_factor')" wrap xs12 align-baseline>
      <v-flex class="mt-2" x3>
        {{ getFactor('seasonality_factor').change_in_price > 0 ? '+' : ''
        }}{{ round(getFactor('seasonality_factor').change_in_price) }}
      </v-flex>
      <v-flex xs9>
        {{ getFactor('seasonality_factor').text }}
      </v-flex>
    </v-layout>

    <v-layout v-if="getFactor('emergency_factor')" wrap xs12 align-baseline>
      <v-flex class="mt-2" x3>
        {{ getFactor('emergency_factor').change_in_price > 0 ? '+' : ''
        }}{{ round(getFactor('emergency_factor').change_in_price) }}
      </v-flex>
      <v-flex xs9>
        {{ getFactor('emergency_factor').text }}
      </v-flex>
    </v-layout>

    <v-layout v-if="getFactor('multi_unit_factor')" wrap xs12 align-baseline>
      <v-flex class="mt-2" x3>
        {{ getFactor('multi_unit_factor').change_in_price > 0 ? '+' : ''
        }}{{ round(getFactor('multi_unit_factor').change_in_price) }}
      </v-flex>
      <v-flex xs9>
        {{ getFactor('multi_unit_factor').text }}
      </v-flex>
    </v-layout>
    <v-layout v-if="getFactor('group_pricing_factor')" wrap xs12 align-baseline>
      <v-flex class="mt-2" x3>
        {{ getFactor('group_pricing_factor').change_in_price > 0 ? '+' : ''
        }}{{ round(getFactor('group_pricing_factor').change_in_price) }}
      </v-flex>
      <v-flex xs9>
        {{ getFactor('group_pricing_factor').text }}
      </v-flex>
    </v-layout>
    <v-layout
      v-if="getFactor('month_occupancy_factor')"
      wrap
      xs12
      align-baseline
    >
      <v-flex class="mt-2" x3>
        {{ getFactor('month_occupancy_factor').change_in_price > 0 ? '+' : ''
        }}{{ round(getFactor('month_occupancy_factor').change_in_price) }}
      </v-flex>
      <v-flex xs9>
        {{ getFactor('month_occupancy_factor').text }}
      </v-flex>
    </v-layout>
    <v-layout v-if="getFactor('day_of_week_factor')" wrap xs12 align-baseline>
      <v-flex class="mt-2" x3>
        {{ getFactor('day_of_week_factor').change_in_price > 0 ? '+' : ''
        }}{{ round(getFactor('day_of_week_factor').change_in_price) }}
      </v-flex>
      <v-flex xs9>
        {{ getFactor('day_of_week_factor').text }}
      </v-flex>
    </v-layout>
    <v-layout v-if="getFactor('one_days_gap')" wrap xs12 align-baseline>
      <v-flex class="mt-2" x3>
        {{ getFactor('one_days_gap').change_in_price > 0 ? '+' : ''
        }}{{ round(getFactor('one_days_gap').change_in_price) }}
      </v-flex>
      <v-flex xs9>
        {{ getFactor('one_days_gap').text }}
      </v-flex>
    </v-layout>
    <v-layout v-if="getFactor('two_days_gap')" wrap xs12 align-baseline>
      <v-flex class="mt-2" x3>
        {{ getFactor('two_days_gap').change_in_price > 0 ? '+' : ''
        }}{{ round(getFactor('two_days_gap').change_in_price) }}
      </v-flex>
      <v-flex xs9>
        {{ getFactor('two_days_gap').text }}
      </v-flex>
    </v-layout>
    <v-layout
      v-if="getFactor('trapped_between_weekends')"
      wrap
      xs12
      align-baseline
    >
      <v-flex class="mt-2" x3>
        {{ getFactor('trapped_between_weekends').change_in_price > 0 ? '+' : ''
        }}{{ round(getFactor('trapped_between_weekends').change_in_price) }}
      </v-flex>
      <v-flex xs9>
        {{ getFactor('trapped_between_weekends').text }}
      </v-flex>
    </v-layout>
    <v-layout v-if="getFactor('event_factor')" wrap xs12 align-baseline>
      <v-flex class="mt-2" x3>
        {{ getFactor('event_factor').change_in_price > 0 ? '+' : ''
        }}{{ round(getFactor('event_factor').change_in_price) }}
      </v-flex>
      <v-flex xs9>
        {{ getFactor('event_factor').text }}
      </v-flex>
    </v-layout>
    <v-layout v-if="getFactor('holiday_factor')" wrap xs12 align-baseline>
      <v-flex class="mt-2" x3>
        {{ getFactor('holiday_factor').change_in_price > 0 ? '+' : ''
        }}{{ round(getFactor('holiday_factor').change_in_price) }}
      </v-flex>
      <v-flex xs9>
        {{ getFactor('holiday_factor').text }}
      </v-flex>
    </v-layout>

    <v-layout v-if="getFactor('min_price_factor')" wrap xs12 align-baseline>
      <v-flex class="mt-2" x3>
        {{ getFactor('min_price_factor').change_in_price > 0 ? '+' : ''
        }}{{ round(getFactor('min_price_factor').change_in_price) }}
      </v-flex>
      <v-flex xs9>
        {{ getFactor('min_price_factor').text }}
      </v-flex>
    </v-layout>
    <v-layout v-if="getFactor('max_price_factor')" wrap xs12 align-baseline>
      <v-flex class="mt-2" x3>
        {{ getFactor('max_price_factor').change_in_price > 0 ? '+' : ''
        }}{{ round(getFactor('max_price_factor').change_in_price) }}
      </v-flex>
      <v-flex xs9>
        {{ getFactor('max_price_factor').text }}
      </v-flex>
    </v-layout>

    <v-layout wrap class="bt-1"> </v-layout>
  </v-layout>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { mapState } from 'vuex'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  props: ['date', 'rate'],
  computed: {
    ...mapState(['currentPricing']),
  },
  methods: {
    getFactor(factorName) {
      if (this.rate) {
        const factor = this.rate[factorName]
        if (factor && factor.change_in_price !== 0) {
          return factor
        }
      }
    },
  },
}
</script>
