<template>
  <v-card>
    <v-card-title class="text-h5 align-center bb-1">
      <v-icon class="pr-2" color="secondary">mdi-credit-card-outline</v-icon>
      Payment Actions
      <v-spacer />
      <v-btn icon @click="onClose">
        <v-icon color="primary">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pt-3 text-body-1">
      <p class="mt-3 mb-8">
        Selected {{ selectedIds.size }} listings with total balance of
        {{ totalAmount }}
      </p>
      <v-btn-toggle
        v-if="isDesignedVr"
        v-model="action"
        color="primary"
        class="d-flex"
        mandatory
      >
        <v-btn
          v-if="!isManagementPayments"
          style="flex: 1"
          value="pay"
          :disabled="!enablePay"
          >Pay</v-btn
        >
        <v-btn v-if="!isManagementPayments" style="flex: 1" value="rollover"
          >Roll Over
        </v-btn>
      </v-btn-toggle>
      <div v-if="!enablePay" class="d-flex">
        <em class="text-body-2 ma-5">
          Pay is disabled because one or more listings have a negative balance
          or a transaction in process
        </em>
      </div>
      <v-divider />

      <div v-if="enablePay && action === 'pay' && natchaSupport" class="mt-10">
        <p class="d-flex justify-space-between align-center">
          <span>1. Download payment file</span>
          <v-btn
            class="text-capitalize"
            color="secondary"
            elevation="0"
            @click="downloadPaymentsFile"
          >
            Download Payment File
          </v-btn>
        </p>
        <p class="d-flex justify-space-between align-center">
          <span>
            2. Upload it to Chase.com and wait for processing confirmation
          </span>
        </p>
        <div>
          <span>3. Mark as processing</span>
          <div class="d-flex align-center pt-5">
            <div class="flex-fill">
              <v-text-field
                v-model="confirmationCode"
                readonly
                :disabled="!confirmationCode"
                label="Confirmation Code"
                prepend-inner-icon="search"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="ml-5">
              <ConfirmationModal
                text="Are you sure ?"
                @action="markAsProcessing"
              >
                <template #activator="{ on }">
                  <v-btn
                    class="text-capitalize"
                    color="primary"
                    elevation="0"
                    :disabled="!markAsProcessingEnabled"
                    v-on="on"
                  >
                    Mark as processing
                  </v-btn>
                </template>
              </ConfirmationModal>
            </div>
          </div>
          <div
            v-if="confirmationCode && !markAsProcessingEnabled"
            class="pt-5 text-center"
          >
            "Mark As Processing" will be enabled in 2 minutes.<br />
            This is the time to process the file in Chase.
          </div>
        </div>
      </div>

      <div v-if="!isManagementPayments && action === 'rollover'" class="mt-10">
        <p class="d-flex justify-space-between align-center">
          <span>Roll over payments to next month</span>
          <v-btn
            class="text-capitalize"
            color="secondary"
            elevation="0"
            @click="balanceRollover"
          >
            Balance Roll Over
          </v-btn>
        </p>
      </div>

      <v-textarea
        v-model="note"
        class="mt-4"
        outlined
        dense
        :label="$t('Note')"
      />
    </v-card-text>

    <v-divider />

    <v-card-actions class="justify-space-around">
      <v-btn depressed @click="onClose">Cancel</v-btn>
      <v-spacer />
      <v-btn
        v-if="!natchaSupport"
        :disabled="!enablePay"
        :loading="loadingPayments"
        color="info"
        @click="payOwners"
        >PAY</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import ConfirmationModal from 'components/modals/confirmation-modal'
import PermissionsMixin from 'components/mixins/permissions-mixin'

export default {
  name: 'ListingsPaymentsModal',
  components: { ConfirmationModal },
  mixins: [PermissionsMixin],
  props: [
    'from',
    'to',
    'date',
    'selectedIds',
    'totalAmount',
    'natchaSupport',
    'enablePay',
    'afterAction',
    'afterFileDownload',
    'type',
  ],
  data: function () {
    return {
      action: this.enablePay ? 'pay' : 'rollover',
      markAsProcessingEnabled: false,
      confirmationCode: '',
      inCallToMarkAsProcessing: false,
      inCallToBalanceRollover: false,
      loadingPayments: false,
      note: null,
    }
  },
  computed: {
    isManagementPayments() {
      return this.type === 'Management Payments'
    },
    urlsType() {
      return this.isManagementPayments ? 'management' : 'owners'
    },
  },
  methods: {
    ...mapActions(['downloadListingsPaymentsFile']),
    onClose() {
      this.$store.commit('hideModal')
    },
    async downloadPaymentsFile() {
      const fileName = await this.downloadListingsPaymentsFile({
        ids: [...this.selectedIds],
        date: this.date,
        type: this.urlsType,
      })
      this.afterFileDownload && this.afterFileDownload(fileName)
      this.confirmationCode = fileName
      this.markAsProcessingEnabled = false
      setTimeout(() => {
        this.markAsProcessingEnabled = true
      }, 60000)
    },
    markAsProcessing() {
      if (!this.inCallToMarkAsProcessing) {
        this.inCallToMarkAsProcessing = true
        axios
          .post(`/api/payments/listings/${this.urlsType}`, {
            ids: [...this.selectedIds],
            date: this.date,
            confirmation_code: this.confirmationCode,
          })
          .then(res => {
            this.onClose()
            this.afterAction && this.afterAction(res)
          })
          .catch(alert)
          .finally(() => {
            this.inCallToMarkAsProcessing = false
          })
      }
    },
    payOwners() {
      this.loadingPayments = true
      axios
        .post(`/api/payments/listings/owners/manual`, {
          ids: [...this.selectedIds],
          from: this.from,
          to: this.to,
          note: this.note,
        })
        .then(res => {
          this.onClose()
          this.false
          this.afterAction && this.afterAction(res)
        })
        .finally(() => {
          this.onClose()
          this.false
        })
    },
    balanceRollover() {
      if (!this.inCallToBalanceRollover) {
        this.inCallToBalanceRollover = true
        axios
          .post(`/api/payments/listings/${this.urlsType}/rollover`, {
            ids: [...this.selectedIds],
            date: this.date,
            note: this.note,
          })
          .then(res => {
            this.onClose()
            this.afterAction && this.afterAction(res)
          })
          .catch(alert)
          .finally(() => {
            this.inCallToBalanceRollover = false
          })
      }
    },
  },
}
</script>

<style scoped></style>
