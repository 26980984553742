<template>
  <v-sheet :color="currentAgent ? 'grey lighten-3' : 'transparent'">
    <v-menu offset-y>
      <template #activator="{ attrs, on }">
        <v-btn
          color="primary"
          class="text-capitalize"
          v-bind="attrs"
          text
          v-on="on"
        >
          {{ currentAgent || `Online agents (${activeCommUsers.length})` }}
        </v-btn>
        <v-btn v-show="currentAgent" color="secondary" text @click="agentClear">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </template>

      <v-list v-if="chatStats">
        <v-list-item
          v-for="agent in activeCommUsers"
          :key="agent.id"
          :disabled="!chatStats.tickets_by_person[agent.id]"
        >
          <v-list-item-title>
            <div class="d-flex align-center">
              <user-avatar
                :name="`${agent.first_name} ${agent.last_name}`"
                :size="30"
                :image="agent.picture"
                class="mr-4"
              />
              {{ `${agent.first_name} ${agent.last_name}` }}
              <v-spacer />
              <v-btn
                class="text-caption ml-4"
                small
                text
                @click="
                  agentPick({
                    id: agent.id,
                    name: `${agent.first_name} ${agent.last_name} - Active`,
                  })
                "
              >
                <span class="success--text font-weight-medium">
                  {{
                    chatStats.tickets_by_person[agent.id] -
                      chatStats.resolved_tickets[agent.id] || 0
                  }}
                </span>
                <span class="ml-1">Active</span>
              </v-btn>
              <v-btn
                class="text-caption ml-2"
                small
                text
                @click="
                  agentPick({
                    id: agent.id,
                    name: `${agent.first_name} ${agent.last_name} - Done`,
                    is_done: true,
                  })
                "
              >
                <span class="warning--text font-weight-medium">
                  {{ chatStats.resolved_tickets[agent.id] || 0 }}
                </span>
                <span class="ml-1">Done</span>
              </v-btn>
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-sheet>
</template>

<script>
import { mapState } from 'vuex'
import UserAvatar from 'components/common/user-avatar'

export default {
  name: 'ChatAgents',
  components: { UserAvatar },
  data() {
    return {
      currentAgent: '',
    }
  },
  computed: {
    ...mapState(['activeCommUsers', 'chatStats']),
  },
  methods: {
    agentPick(agent) {
      if (this.chatStats.tickets_by_person[agent.id]) {
        this.$emit('view-active', { id: agent.id, is_done: agent.is_done })
      }
      this.currentAgent = `${agent.name}`
    },
    agentClear() {
      this.currentAgent = ''
      this.$emit('view-active', {})
    },
  },
}
</script>

<style scoped></style>
