import VueRouter from 'vue-router'
import Vue from 'vue'
import last from 'lodash/fp/last'
Vue.use(VueRouter)

import RootLayout from 'components/layouts/root-layout'
import Taskim from 'components/taskim/taskim'
import Project from 'components/projects/project'
import Feed from 'components/feed.vue'
import Expense from 'components/expenses/expense'
import Automate from 'components/automations-dash'
import Estimates from 'components/estimates/estimates-page'
import DashboardPage from 'components/dashboard-page'
import SalesPanel from 'components/sales-panel'
import ListingsSearch from 'components/listings-search'
import BaseChat from 'components/base-chat'
import InvestmentFinder from 'components/investment-finder'
import ListingSetups from 'components/listing-setups'
import ListingManagement from 'components/listing_management'
import ExperienceManager from 'components/experience-manager'
import PricingPage from 'components/pricing-page'
import ListingPage from 'components/listing/listing-page'
import Activity from 'components/activity'
import ListingTaskCatalogItemsTable from 'components/listing-tasks-catalog/listing-task-catalog-items-table'
import Marketing from 'components/marketing'
import Guest from 'components/guest'
import AIManager from 'components/ai/ai-manager.vue'
import ManagementPage from 'components/management-page'
import CreateTenantPage from 'components/create-tenant-page'
import InventoryPage from 'components/inventory/inventory-page'
import ListingSetupPage from 'components/listing-setup-page'
import Payables from 'components/payables'
import BankFeeds from 'components/bank-feeds'
import Financials from 'components/financials'
import Pricings from 'components/pricings'
import ListingTasksDash from 'components/listing-tasks-dash'
import AutoTasks from 'components/auto-tasks'
import AutoMessages from 'components/auto-messages'
import ListingReportLayout from 'components/listing-report/listing-report-layout'
import RegionReportPage from 'components/listing-report/region-report-page'
import ListingReportSingle from 'components/listing-report/listing-report-single'
import Performance from 'components/performance'
import DevTools from 'components/dev-tools'
import ListingTaskLayout from 'components/listing-tasks/single-listing-task/listing-task-layout'
import ReservationManager from 'components/reservation-manager'
import ReservationPage from 'components/reservation/reservation-page'
import RequestsTable from 'components/inventory/requests/requests-table'
import Error from 'components/error'
import UserLayout from 'components/user/user-layout'
import IOTDashboard from 'components/iot/iot-dashboard'
import StoreView from 'components/store/store-view'
import HotelDashboardPage from 'components/hotel-dashboard/hotel-dashboard-page'
import MultiCalendar from 'components/calendar/multi-calendar'
import PortfolioPage from 'components/portfolio/portfolio-page.vue'
import GuestExperience from 'components/crm/guest-experience'
import ReviewCRM from 'components/crm/review-crm.vue'
import GuestsMgmt from 'components/guests/guests-mgmt'
import GuestBook from 'components/guests/guest-book.vue'
import Login from 'components/login'
import ResetPasswordPage from 'components/reset-password-page'
import ListingTaskTrackerLayout from 'components/layouts/listing-task-tracker-layout'
import ListingTaskTracker from 'components/listing-tasks/listing-task-tracker'
import ListingTaskReport from 'components/listing-tasks/listing-task-report'
import Integrations from 'components/integrations.vue'
import IntegrationPage from 'components/marketplace/integration-page.vue'
import IntegrationsLayout from 'components/integrations-layout.vue'
import CmDash from 'components/channel-management/cm-dash.vue'
import AgentReservations from 'components/agents/agent-reservations.vue'
import Storybook from 'components/storybook.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/storybook',
    component: Storybook,
  },
  { path: '/', redirect: '/dashboard' },
  {
    path: '/dashboard',
    component: RootLayout,
    children: [
      { name: 'Taskim', path: 'taskim/:boardId?', component: Taskim },
      { name: 'Feed', path: 'feeds/:feedId?', component: Feed },
      { name: 'GuestBook', path: 'guest-book', component: GuestBook },
      { name: 'ChannelManager', path: 'cm/:paramsTab?', component: CmDash },
      {
        name: 'Project',
        path: 'projects/:projectId/:paramsTab?',
        component: Project,
      },
      { name: 'Expense', path: 'expenses/:expenseId', component: Expense },
      { name: 'Automate', path: 'automate/:paramsTab?', component: Automate },
      {
        name: 'Estimates',
        path: 'estimates/:paramsTab?/:estimateId?',
        component: Estimates,
      },
      { name: 'Listings', path: 'listings', component: DashboardPage },
      { path: 'sales', component: SalesPanel },
      { path: 'listings-search', component: ListingsSearch },
      { path: 'sales-chat/:id?', component: BaseChat, name: 'guests-chat' },
      { path: 'investment-finder', component: InvestmentFinder },
      { path: 'listings-setup/:paramsTab?', component: ListingSetups },
      {
        name: 'manage-property',
        path: 'edit/:id/:paramsTab?',
        component: ListingManagement,
      },
      { path: 'experience-management', component: ExperienceManager },
      { path: 'pricing/:id', component: PricingPage },
      {
        name: 'view-property',
        path: 'property/:id/:paramsTab?',
        component: ListingPage,
      },
      { path: 'company-activity', component: Activity },
      { path: 'catalog', component: ListingTaskCatalogItemsTable },
      { path: 'marketing/:paramsTab?', component: Marketing },
      { path: 'guests/:id', component: Guest },
      { path: 'ai-config/:paramsTab?', component: AIManager },
      {
        path: 'management/:paramsTab?',
        component: ManagementPage,
        name: 'setting-page',
        children: [
          {
            name: 'integration-layout',
            path: '/',
            component: IntegrationsLayout,
            children: [
              {
                path: '/',
                component: Integrations,
              },
              {
                path: 'integration/:name',
                name: 'integration-page',
                component: IntegrationPage,
              },
            ],
          },
        ],
      },
      { path: 'inventory/:paramsTab?', component: InventoryPage },
      {
        path: '/dashboard/listing-setup/:id/:paramsTab?',
        component: ListingSetupPage,
      },
      {
        name: 'payables',
        path: 'payables/:paramsTab?',
        component: Payables,
      },
      {
        name: 'bank-feeds',
        path: 'bank-feeds/:paramsTab?',
        component: BankFeeds,
      },
      {
        name: 'financials',
        path: 'financials/:paramsTab?',
        component: Financials,
      },
      { path: 'pricing-panel/:paramsTab?', component: Pricings },
      {
        name: 'service-calls',
        path: 'service-calls/:paramsTab?',
        component: ListingTasksDash,
        children: [
          {
            name: 'area-report',
            path: '/',
            component: ListingReportLayout,
            children: [
              { path: '/', name: 'region-report', component: RegionReportPage },
              {
                path: 'listing/:id',
                name: 'listing-report',
                component: ListingReportSingle,
              },
            ],
          },
        ],
      },
      { path: 'performance/:paramsTab?', component: Performance },
      { path: 'dev-tools/:paramsTab?', component: DevTools },
      {
        path: 'listing-task/:id',
        component: ListingTaskLayout,
        name: 'lt-page',
      },
      { path: 'reservations-manage', component: ReservationManager },
      { path: 'reservation/:id', component: ReservationPage },
      { path: 'field-management', component: RequestsTable },
      { path: 'error', component: Error },
      { path: 'user-profile/:paramsTab?', component: UserLayout },
      { path: 'iot', component: IOTDashboard },
      { path: 'store/:paramsTab?', component: StoreView },
      { path: 'hotel-dashboard', component: HotelDashboardPage },
      {
        path: 'multi-calendar',
        name: 'multi-calendar',
        component: MultiCalendar,
      },
      {
        path: 'portfolio/:paramsTab?',
        name: 'portfolio',
        component: PortfolioPage,
      },
      {
        path: 'guest-experience/:paramsTab?/:conversationId?',
        name: 'sales',
        component: GuestExperience,
      },
      {
        path: 'guest-experience/:paramsTab?',
        name: 'crm',
        component: GuestExperience,
      },
      {
        path: 'reviews-dashboard/:paramsTab?',
        name: 'review-crm',
        component: ReviewCRM,
      },
      {
        path: 'guest-mgmt/:paramsTab?',
        name: 'guest-exp',
        component: GuestsMgmt,
      },
      {
        path: 'auto-tasks',
        name: 'auto-tasks',
        component: AutoTasks,
      },
      {
        path: 'auto-messages',
        name: 'auto-messages',
        component: AutoMessages,
      },
      {
        path: 'agent-reservations',
        name: 'agent-reservations',
        component: AgentReservations,
      },
      { path: '/', redirect: '/dashboard/listings' },
    ],
  },
  { path: '/login', component: Login },
  { path: '/reset-password', component: ResetPasswordPage },
  {
    path: '/tourist-lt',
    component: ListingTaskTrackerLayout,
    children: [{ path: '/tourist-lt/:id', component: ListingTaskTracker }],
  },
  { path: '/task-report/:id', component: ListingTaskReport },
  { path: '/management/tenant', component: CreateTenantPage },
]

export const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior({ matched: matched1 }, { matched: matched2 }) {
    if (last(matched1).path !== last(matched2).path) {
      return { x: 0, y: 0 }
    }
  },
})
