<template>
  <v-navigation-drawer
    temporary
    stateless
    right
    app
    class="fill-available"
    :width="1400"
    :value="Boolean(conversationId) || Boolean(newConvUser)"
  >
    <div v-if="conversationId || newConvUser" v-click-outside="onClickOutside">
      <div class="d-flex flex h-100 conv-container">
        <v-sheet
          v-if="conversation"
          min-width="300"
          class="flex-grow-1 d-flex flex-column"
        >
          <div class="">
            <div
              v-for="issue in conversation.outstanding_issues"
              :key="issue.id"
            >
              <div
                class="w-100 amber lighten-4 font-weight-medium py-2 px-4 font-weight-medium text-caption"
                style="color: #a34904"
              >
                <span class="font-weight-medium"
                  >{{ issue.task_type }}
                  <v-icon small>mdi-chevron-right</v-icon>
                  {{ issue.sub_topic }}:</span
                ><a
                  class="ml-2 text-decoration-underline"
                  style="color: #a34904"
                  target="_blank"
                  :href="`dashboard/listing-task/${issue.id}`"
                  >{{ issue.description }}
                </a>
              </div>
              <v-divider class="grey lighten-2" />
            </div>
          </div>
          <div class="d-flex flex-column h-100">
            <div class="flex-grow-1" style="height: 0">
              <v-progress-linear v-show="conversationLoading" indeterminate />
              <conversation-messages
                v-if="conversation.thread_plus_dvr_log && !conversationLoading"
                :conversation="conversation"
                :drawer-mode="true"
                @update-conversations="updateConversations"
                @create-service-call="openServiceCallModal"
                @approve-ai-suggestion="approveAiSuggestion"
              />
            </div>
            <div>
              <conversation-chat-box
                v-if="conversation"
                ref="conversationChatBox"
                :new-conv-user="newConvUser"
                @create-service-call="openServiceCallModal"
                @update-conversation-fun-level="updateConversationFunLevel"
              />
            </div>
          </div>
        </v-sheet>
        <v-sheet class="d-flex flex-column" top="64px">
          <conversation-info
            v-if="conversation"
            id="conversation-info"
            :key="conversation.id"
            class="transparent"
            :fetching="conversationLoading"
            :conversation="conversation"
          />
        </v-sheet>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ConversationChatBox from 'components/conversation-chat-box.vue'
import ConversationMessages from 'components/conversation-messages.vue'
import ConversationInfo from 'components/conversation-info.vue'
import BoomFeature from 'components/mixins/boom-feature'

export default {
  mixins: [BoomFeature],
  components: {
    ConversationChatBox,
    ConversationMessages,
    ConversationInfo,
  },
  mounted() {
    if (this.conversationId) {
      this.getConversation(this.conversationId)
    }
  },
  computed: {
    ...mapState(['conversationLoading']),
    conversationId() {
      return this.$route.query.conversation || this.$route.params.conversationId
    },
    newConvUser() {
      return this.$route.query.newConvUser
    },
    messages() {
      if (this.notesOn) {
        return this.notes
      }
      return this.conversation && this.conversation.thread_plus_dvr_log
    },
    conversation() {
      return this.newConvUser ? {} : this.$store.getters.currentConversation
    },
  },
  methods: {
    ...mapActions(['getChatConversation', 'setServiceCallModalOpened']),
    updateConversations() {
      this.$emit('update-conversations')
    },
    getConversation(conversationId) {
      this.getChatConversation(conversationId)
    },
    onClickOutside(e) {
      if (e.target.className === 'v-overlay__scrim') {
        if (this.$route.params.conversationId) {
          this.$router.push('/dashboard/guest-experience')
        } else {
          this.$router.push({ query: {} })
        }
        this.$emit('on-close')
      }
    },
    scrollToEnd() {
      var content = this.$refs.msgscroll
      if (content) {
        content.scrollTop = content.scrollHeight
      }
    },
    openServiceCallModal(payload) {
      this.setServiceCallModalOpened({ opened: true, payload: payload })
    },
    updateConversationFunLevel() {
      this.$store.dispatch('conversationUpdate', { id: this.conversation.id })
    },
    approveAiSuggestion(message_body) {
      this.$refs.conversationChatBox.updateMessage(message_body)
    },
  },
  watch: {
    conversationId(value) {
      if (value) {
        this.getConversation(value)
      }
    },
    messages() {
      setTimeout(() => {
        this.scrollToEnd()
      }, 100)
    },
  },
}
</script>
<style scoped>
.fill-available {
  height: calc(100vh - 64px) !important;
  height: calc(100svh - 64px) !important;
  top: 64px !important;
}
</style>
