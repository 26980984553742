<template>
  <div v-show="!loading">
    <div class="grey lighten-4">
      <v-container class="max-w-1600 pb-0">
        <v-tabs
          v-model="paramsTab"
          slider-color="white"
          background-color="#f6f6f7"
          active-class="white"
          :grow="isMobile"
        >
          <v-tab
            :disabled="
              !(
                showModule('accounting') &&
                (hasBankAccounts || isAdmin || isAccountant)
              )
            "
            href="#accounting"
          >
            <v-icon class="mr-md-2">$calculator</v-icon>
            <span class="d-none d-md-inline text-capitalize">{{
              $t('Bank Transactions')
            }}</span>
          </v-tab>
          <v-tab
            v-if="
              showModule('accounting') && hasAbility('modify-bank-accounts')
            "
            href="#bank-accounts"
          >
            <v-icon class="mr-md-2">mdi-bank-outline</v-icon>
            <span class="d-none d-md-inline text-capitalize"
              >Bank Accounts</span
            >
          </v-tab>
          <v-tab
            v-if="
              showModule('accounting') && hasAbility('modify-bank-accounts')
            "
            href="#bank-account-settings"
          >
            <v-icon class="mr-md-2">mdi-cog-outline</v-icon>
            <span class="d-none d-md-inline text-capitalize"
              >Bank Account Settings</span
            >
          </v-tab>
        </v-tabs>
      </v-container>
    </div>
    <div>
      <div>
        <v-tabs-items v-model="paramsTab" touchless>
          <v-tab-item
            v-if="hasAbility('modify-bank-accounts')"
            value="bank-accounts"
          >
            <bank-accounts />
          </v-tab-item>
          <v-tab-item
            v-if="hasBankAccounts || isAdmin || isAccountant"
            value="accounting"
            class="pl-4 pb-2 pr-4 main-background"
          >
            <bank-transactions class="pt-3" />
          </v-tab-item>
          <v-tab-item
            v-if="hasAbility('modify-bank-accounts')"
            value="bank-account-settings"
          >
            <bank-account-settings />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

import BankTransactions from 'components/bank-transactions'
import get from 'lodash/fp/get'
import isEmpty from 'lodash/fp/isEmpty'
import DeviceMixin from 'components/mixins/device-mixin'
import BankAccounts from 'components/bank-accounts.vue'
import BankAccountSettings from 'components/bank-account-settings.vue'

export default {
  components: {
    BankAccounts,
    BankTransactions,
    BankAccountSettings,
  },
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin],
  computed: {
    hasBankAccounts() {
      return !isEmpty(get('bank_accounts_numbers', this.$store.state.user))
    },
    config() {
      return this.$store.state.app.configuration
    },
  },
}
</script>
