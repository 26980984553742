<template>
  <form-dialog title="Add Reservation Sellers" @submit="markAsSale(true)">
    <template #activator="{ on }">
      <v-btn color="success" small class="mr-2" v-on="on">
        <v-icon small>$dollarSack</v-icon>
        <span v-if="!isMobile" class="ml-2">SALE</span>
      </v-btn>
    </template>
    <contractor-select
      label="Select Users"
      :allow-multiple="true"
      :value.sync="sellers"
    />
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import ContractorSelect from 'components/contractor-select'
import deviceMixin from 'components/mixins/device-mixin'
export default {
  name: 'SaleBtn',
  components: { ContractorSelect, FormDialog },
  mixins: [deviceMixin],
  props: ['reservationId', 'conversationId', 'reservationSellers'],
  data() {
    return {
      sellers: this.reservationSellers,
    }
  },
  methods: {
    async markAsSale() {
      await this.$store.dispatch('conversationUpdate', {
        mark_sale: true,
        reservation_sellers: this.sellers,
        res_id: this.reservationId,
        id: this.conversationId,
      })
      this.$emit('on-success')
    },
  },
  watch: {
    reservationId(_value) {
      this.sellers = this.reservationSellers
    },
  },
}
</script>

<style scoped></style>
