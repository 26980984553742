<template>
  <v-data-table
    v-if="items && items.length"
    mobile-breakpoint="0"
    :headers="headers"
    :items="items"
    fixed-header
    height="60vh"
    :footer-props="{
      itemsPerPageOptions: [30],
    }"
    :options="options"
    :server-items-length="options.totalItems"
    @update:page="updatePage"
    @click:row="$emit('row-click', $event)"
  >
    <template #[`item.fun_level`]="{ item }">
      <v-rating readonly length="5" size="10" :value="item.fun_level" />
    </template>
    <template #[`item.guest_full_name`]="{ item }">
      {{ item.guest_full_name || item.user?.full_name }}
    </template>
    <template #[`item.reservation_price`]="{ item }">
      <strong>{{ dollarFormatter(item.reservation_price) || '-' }}</strong>
    </template>
    <template #[`item.last_message_from_guest`]="{ item }">
      <div v-if="item.last_message_from_guest">
        <span>{{ fromNow(item.last_message_from_guest) || '-' }}</span>
        <span class="ml-2">({{ conversationThreadSize(item) }})</span>
      </div>
    </template>
    <template #[`item.extra_data.reservation_check_in`]="{ item }">
      <span class="d-flex min-w-200"
        ><span>{{ parseDate(item.extra_data.reservation_check_in) }}</span>
        <v-icon small class="mx-1">mdi-arrow-right-thick </v-icon>
        <span> {{ parseDate(item.extra_data.reservation_check_out) }}</span>
      </span>
    </template>
    <template #[`item.assigned_user_id`]="{ item }">
      <conversation-assignee
        :after-change="updateConversations"
        :pre-selected="userById(item.assigned_user_id)"
        :conversation="item"
      />
    </template>
    <template #[`item.follow_up_at`]="{ item }">
      <span>{{ parseDate(item.follow_up_at) || '-' }}</span>
    </template>
    <template #[`item.reservation_status`]="{ item }">
      <div style="min-width: 250px">
        <v-chip :color="resStatusColor(item.reservation_status)" small>
          {{ item.reservation_status }}
        </v-chip>
        <v-icon v-if="hasSeller(item)" large class="mx-2">$sold</v-icon>
        <span v-if="item.escalated" class="tooltip">
          <v-icon
            large
            class="mx-2 tooltipicon"
            style="
              margin-left: 15px;
              color: var(--v-warning-base) !important;
              font-size: 26px;
            "
          >
            mdi-bell
          </v-icon>
          <span v-if="item.extra_data.escalation_reason" class="tooltiptext">
            {{ item.extra_data.escalation_reason }}
          </span>
        </span>
      </div>
    </template>
    <template #[`item.actions`]="{ item }">
      <div class="d-flex justify-center align-center">
        <follow-up-modal
          :conversation="item"
          :minimal="true"
          @on-action="onModalUpdate"
        >
          <template #customBtn="{ on }">
            <v-btn
              v-if="!item.follow_up_at"
              depressed
              text
              color="blue-grey darken-2"
              small
              v-on="on"
            >
              <v-icon small class="mr-1">mdi-archive</v-icon>
              Move
            </v-btn>
          </template>
        </follow-up-modal>
        <sale-btn
          v-if="
            mode !== 'cs' &&
            item.reservation_status === 'confirmed' &&
            item.active_reservation
          "
          :reservation-id="item.active_reservation.id"
          :conversation-id="item.id"
          :reservation-sellers="item.active_reservation.reservation_sellers"
          @on-success="updateConversations"
        />
      </div>
    </template>
  </v-data-table>
  <div v-else class="pt-10 text-body-1 text-center white text--secondary">
    <div class="">No messages</div>
  </div>
</template>

<script>
import formattersMixin from 'components/mixins/formatters-mixin'
import colorsMixin from 'components/mixins/colors-mixin'
import navigationMixin from 'components/mixins/navigation-mixin'
import common_functions from 'components/mixins/common_functions'
import followUpModal from 'components/conversations/follow-up-modal.vue'
import ConversationAssignee from '../chat/conversation-assignee'
import SaleBtn from 'components/crm/sale-btn'

export default {
  components: {
    SaleBtn,
    ConversationAssignee,
    followUpModal,
  },
  props: ['headers', 'items', 'options', 'mode'],
  mixins: [formattersMixin, colorsMixin, navigationMixin, common_functions],
  methods: {
    conversationThreadSize(conversation) {
      return (
        conversation.extra_data.guest_messages ||
        0 + conversation.extra_data.host_messages ||
        0
      )
    },
    updatePage(page) {
      this.$emit('paginate', page)
    },
    onModalUpdate(state) {
      if (['setFollowUp', 'archive', 'unfollow'].includes(state)) {
        this.$emit('update-conversations')
      }
    },
    updateConversations() {
      this.$emit('update-conversations')
    },
    hasSeller({ active_reservation }) {
      return !!(
        active_reservation && active_reservation.reservation_sellers.length
      )
    },
  },
}
</script>

<style>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: gray;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 15px;
  left: -140px;
  top: 37px;
  position: absolute;
  z-index: 1;
}
.tooltip .tooltipicon {
  font-size: 20px;
  margin: 7px;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
