<template>
  <div class="d-flex py-4 align-center">
    <div>
      Pricing tool:
      <span class="text-uppercase font-weight-medium">{{
        listing.pricing_engine
      }}</span>
    </div>
    <v-btn class="mx-2" small color="primary" @click="onLinkClick"
      >pricing setting</v-btn
    >
    <v-btn class="mx-2" small color="primary" outlined @click="onResyncClick"
      >Re-sync</v-btn
    >
    <div v-if="refteshedTime" class="text-caption font-weight-light">
      Last synced on:<br />
      <span>{{ refteshedTime }}</span>
    </div>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import axios from '@/axios/config'
export default {
  props: ['listing'],
  mixins: [CommonFunctions],
  methods: {
    onLinkClick() {
      const path = this.listing.pricing_page_url
      this.listing.pricing_engine === 'Boom'
        ? (window.location.href = path)
        : window.open(path, '_blank')
    },
    async onResyncClick() {
      this.$store.commit('updateLoading', true)
      try {
        const { data } = await axios.get(`
          /api/listings/${this.listing.id}/force-sync-prices
        `)
        this.$store.commit('updateLoading', false)
        this.$emit('submit', data)
      } catch (error) {
        alert(error)
        this.$store.commit('updateLoading', false)
      }
    },
  },
  computed: {
    refteshedTime() {
      if (this.listing.pricing_refreshed_at) {
        return this.$moment(this.listing.pricing_refreshed_at).format(
          'MMM DD YYYY HH:mm'
        )
      } else {
        return ''
      }
    },
  },
}
</script>
