<template>
  <tr>
    <td class="text-center">
      <v-switch v-model="isActive" @change="itemChange" />
    </td>
    <td class="text-center">
      <a class="text-decoration-underline" @click="updateEditRule(item)">{{
        item.name
      }}</a>
    </td>
    <td class="text-center">
      <div v-if="item.is_ai">
        <v-chip class="secondary" small>AI action</v-chip>
      </div>
      <div v-else>
        <v-chip class="secondary" small>Automation task</v-chip>
      </div>
    </td>
    <td class="text-center">
      <div v-if="item.allowed_channels.length !== 0">
        <tag
          v-for="channel in item.allowed_channels"
          :key="channel"
          color="primary"
          :inverted="true"
          size="sm"
          rounded
          >{{ channel }}</tag
        >
      </div>
    </td>
    <td class="text-center">
      <div v-if="item.ignore_channels.length !== 0">
        <tag
          v-for="channel in item.ignore_channels"
          :key="channel"
          color="primary"
          :inverted="true"
          size="sm"
          rounded
          >{{ channel }}</tag
        >
      </div>
    </td>
    <td class="text-center">
      <div>
        <v-chip v-if="communicationMethods" class="secondary" small>
          {{ communicationMethods }}
        </v-chip>
      </div>
    </td>
    <td class="text-center justify-center">
      <v-col>
        <div v-if="item.whitelist.length === 0">
          <tag color="primary" :inverted="true" size="sm">All Listings</tag>
        </div>
        <div v-else>
          <tag color="primary" :inverted="true" size="sm"
            >{{ item.whitelist.length }} listings</tag
          >
        </div>
      </v-col>
      <v-col v-if="item.blacklist.length !== 0">
        <div>
          <tag color="secondary" :inverted="true" size="sm"
            >{{ item.blacklist.length }} Exluded</tag
          >
        </div></v-col
      >
    </td>
    <td class="text-center">
      <tag
        v-if="item.sent_at_number"
        bg-color="grey lighten-2"
        color="black"
        rounded
      >
        {{ item.sent_at_number }}
      </tag>
      <v-icon v-if="item.sent_at_number" color="grey lighten-1" class="mx-1"
        >mdi-chevron-right</v-icon
      >
      <tag
        v-if="item.sent_at_period"
        bg-color="grey lighten-2"
        color="black"
        rounded
      >
        {{ item.sent_at_period }}
      </tag>
      <v-icon v-if="item.sent_at_period" color="grey lighten-1" class="mx-1"
        >mdi-chevron-right</v-icon
      >
      <tag bg-color="grey lighten-2" color="black" rounded>
        {{ item.sent_at_timing }}
      </tag>
      <v-icon color="grey lighten-1" class="mx-1">mdi-chevron-right</v-icon>
      <tag bg-color="grey lighten-2" color="black" rounded>{{
        item.sent_at_event
      }}</tag>
    </td>
    <td class="text-center">
      <div v-if="item.stats.last_10_sent.length">
        <p>Total sent: {{ item.stats.total_sent }}</p>
        <p
          v-for="conversation in item.stats.last_10_sent"
          :key="conversation.guesty_id"
        >
          <a
            class="text-decoration-none black--text"
            target="_blank"
            :href="'/dashboard/sales-chat/' + conversation.guesty_id"
            ><span class="mr-2">Open chat</span>
            <v-icon small>fas fa-external-link-alt</v-icon>
          </a>
        </p>
      </div>
    </td>
    <td class="text-center">
      <ConfirmationModal
        v-if="managementRoles && item"
        :text="`Are you sure you want to delete automated message ${item.name} ?`"
        @action="itemDelete(item)"
      >
        <template #activator="{ on }">
          <v-icon small class="mr-2 clickable" v-on="on"> delete</v-icon>
        </template>
      </ConfirmationModal>
    </td>
  </tr>
</template>

<script>
import axios from 'axios'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import Tag from 'components/common/tag.vue'
import ConfirmationModal from 'components/modals/confirmation-modal'

export default {
  components: { Tag, ConfirmationModal },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['item', 'afterChange', 'whatsappTemplates'],
  data() {
    return {
      singleSelect: false,
      menu: false,
      menu2: false,
      isActive: this.item.active,
    }
  },
  computed: {
    communicationMethods() {
      let methods = []
      if (this.item.send_email) methods.push('email')
      if (this.item.send_sms) methods.push('sms')
      if (this.item.send_whatsapp) methods.push('whatsapp')
      if (this.item.send_channel) methods.push('channel')
      return methods.join(' | ')
    },
  },
  methods: {
    updateEditRule(item) {
      this.$store.commit('showModal', {
        name: 'AutoMessageRuleModal',
        props: {
          editMode: true,
          afterSaveFunc: this.afterChange,
          currentRule: Object.assign({}, item),
          templates: this.whatsappTemplates,
        },
        isPersistent: false,
      })
    },
    itemDelete(item) {
      this.$store.commit('updateListingLoading', true)
      axios
        .delete(`/api/auto-message-rules/${item.id}`)
        .then(() => {
          this.afterChange ? this.afterChange() : this.$router.go(0)
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.$store.commit('updateListingLoading', false)
        })
    },
    itemChange() {
      if (!this.isActive) {
        const userConfirm = confirm('Are you sure you want to disable?')
        if (!userConfirm) {
          return
        }
      }

      this.$store.commit('updateListingLoading', true)
      axios
        .patch(`/api/auto-message-rules/${this.item.id}`, {
          active: this.isActive,
        })
        .then(() => {
          this.$store.commit('updateListingLoading', false)
        })
        .catch(error => {
          console.error(error)
        })
    },
  },
}
</script>
