<template>
  <v-card>
    <v-card-title class="px-6 pt-6">
      <div class="p-relative flex">
        <h2 class="text-h6">Add new listing</h2>
        <v-icon class="close-button" @click="close">close</v-icon>
      </div>
    </v-card-title>
    <v-sheet class="white overflow-y-auto">
      <v-form
        ref="createListing"
        lazy-validation
        @submit.prevent="createListing"
      >
        <v-card-text class="px-6">
          <p class="text-body-1 font-weight-medium">Location details</p>
          <div class="mb-1">
            <v-select
              v-model="listingObj.listing_type"
              label="Listing Type"
              :items="['regular', 'unit', 'multi-unit', 'hotel']"
              outlined
              dense
              :rules="[required]"
              @change="typeChanged"
            />
          </div>
          <div class="mb-1">
            <v-text-field
              v-model="listingObj.name"
              label="Nickname"
              outlined
              dense
              :rules="[required]"
            />
          </div>
          <v-select
            v-model="listingObj.stage"
            class="text-capitalize"
            :items="['regular', 'long-term', 'setup']"
            label="Listing stage"
            item-text="name"
            item-value="id"
            outlined
            dense
          />
          <div class="mb-1">
            <v-text-field
              v-if="listingObj.stage !== 'setup'"
              v-model="listingObj.title"
              label="Title"
              outlined
              dense
              :rules="titleRules"
              :min="20"
            />
          </div>
          <div v-if="false" class="mb-1">
            <v-text-field
              v-model="listingObj.city"
              label="City"
              outlined
              dense
              :rules="[required]"
            />
          </div>
          <div v-if="false" class="mb-1">
            <v-text-field
              v-model="listingObj.country"
              label="Country"
              outlined
              dense
              :rules="[required]"
            />
          </div>
          <div>
            <v-text-field
              id="autocomplete"
              v-model="listingObj.address"
              label="Address"
              outlined
              dense
              :rules="[required]"
            />
          </div>
          <div v-if="false" class="mb-1">
            <v-text-field
              v-model="listingObj.lat"
              label="Latitude"
              outlined
              dense
            />
          </div>
          <div v-if="false" class="mb-1">
            <v-text-field
              v-model="listingObj.lng"
              label="Longitude"
              outlined
              dense
            />
          </div>
          <div v-if="false" class="mb-1">
            <v-text-field
              v-model="listingObj.address"
              label="FormattedAddress"
              outlined
              dense
            />
          </div>
          <div class="mb-1">
            <v-textarea
              v-model="listingObj.description"
              label="Description"
              outlined
              dense
            />
          </div>
          <v-flex v-if="false" xs8>
            <v-text-field
              v-model="listingObj.zip_code"
              label="Zip Code"
              outlined
              dense
              :rules="zipCodeRules"
            />
          </v-flex>
          <div v-if="false" class="mb-1">
            <v-text-field
              v-model="listingObj.street"
              label="Street"
              outlined
              dense
              :rules="[required]"
            />
          </div>
          <div
            v-if="
              listingObj.listing_type === 'hotel' ||
              listingObj.listing_type === 'multi-unit'
            "
            class="mb-6"
          >
            <multi-property-picker
              v-model="listingObj.units"
              :close-on-content-click="false"
            />
          </div>
          <v-row no-gutters>
            <v-col cols="6" class="px-1">
              <v-text-field
                v-model="listingObj.beds"
                label="Num of bedrooms"
                type="number"
                prepend-inner-icon="$bedroom"
                :rules="[required, isPositive]"
                :max="30"
                :min="1"
                outlined
                dense
              />
            </v-col>
            <v-col cols="6" class="px-1">
              <v-text-field
                v-model="listingObj.baths"
                label="Baths"
                prepend-inner-icon="$bath"
                outlined
                dense
                hide-details
                required
              />
            </v-col>
            <v-col v-if="listingObj.stage === 'regular'" cols="6" class="px-1">
              <v-select
                v-model="listingObj.ota_type"
                label="OTA Type"
                :items="otaTypes"
                outlined
                dense
                :rules="[required]"
              />
            </v-col>
            <v-col v-if="listingObj.stage === 'regular'" cols="6" class="px-1">
              <v-text-field
                v-model="listingObj.accommodates"
                label="Maximum Guests"
                type="number"
                :rules="[required, isPositive]"
                :min="1"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="listingObj.investor_id"
                :items="investors"
                label="Main investor"
                item-text="name"
                item-value="id"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <p
            v-if="listingObj.stage === 'setup'"
            class="font-weight-medium text-body-1"
          >
            Project Details
          </p>
          <v-row v-if="listingObj.stage === 'setup'">
            <v-col cols="12" sm="4">
              <v-checkbox
                v-model="listingObj.has_pool"
                label="Pool"
                class="mt-0"
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-checkbox
                v-model="listingObj.has_jacuzzi"
                label="Jacuzzi"
                class="mt-0"
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-checkbox
                v-model="listingObj.has_garden"
                label="Garden"
                class="mt-0"
                dense
                hide-details
              />
            </v-col>
          </v-row>

          <v-row v-if="listingObj.stage === 'setup'">
            <v-col cols="6" md="4">
              <v-select
                v-model="listingObj.status"
                :items="[
                  'Upcoming',
                  'Pre Setup',
                  'Setup',
                  'Construction',
                  'Design',
                  'Done',
                ]"
                label="Setup Status"
                outlined
                dense
                hide-details
                required
              />
            </v-col>
            <v-col cols="6" md="4">
              <v-select
                v-model="listingObj.priority"
                :items="priorities"
                :label="$t('Priority')"
                dense
                outlined
                hide-details
              >
                <template #item="{ item }">
                  <v-icon class="mr-2" :color="priorityColor(item)" x-small
                    >mdi-circle</v-icon
                  >
                  <span class="text-capitalize">
                    {{ item }}
                  </span>
                </template>
                <template #selection="{ item }">
                  <v-icon class="mr-2" :color="priorityColor(item)" x-small
                    >mdi-circle</v-icon
                  >
                  <span class="text-capitalize">
                    {{ item }}
                  </span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="6" md="4">
              <date-picker
                v-model="listingObj.launch_date"
                label="Launch Date"
              />
            </v-col>
          </v-row>
          <v-divider v-if="listingObj.stage === 'setup'"></v-divider>
          <listing-setup-scopes
            v-if="listingObj.stage === 'setup'"
            class="mt-3"
            :scopes.sync="listingObj.scopes"
          />
        </v-card-text>
        <v-card-actions class="px-6 py-4">
          <v-spacer />
          <v-btn
            class="text-capitalize"
            color="secondary"
            text
            @click.native="close"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            :disabled="saveDisabled"
            class="text-capitalize"
            elevation="0"
            type="submit"
            >Save</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-sheet>
  </v-card>
</template>

<script>
import DatePicker from 'components/form-fields/date-picker.vue'
import ListingSetupScopes from 'components/listing-setup-scopes.vue'
import MultiPropertyPicker from 'components/multi-property-picker.vue'
import FormRules from 'components/mixins/form-rules-mixin'
import ColorsMixin from 'components/mixins/colors-mixin'
import { mapGetters } from 'vuex'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import { loadGmaps } from '@/modules/remote-resources'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'ListingCreateModal',
  props: ['initialFields'],
  components: { DatePicker, ListingSetupScopes, MultiPropertyPicker },
  mixins: [FormRules, ColorsMixin],
  data() {
    return {
      saveDisabled: false,
      formFields: {
        zip_code: '',
        country: '',
        street: '',
        lat: '',
        lng: '',
        city: '',
        address: '',
        description: '',
        listing_type: 'regular',
        check_in_start: '16:00',
        check_in_end: '22:00',
        check_out_time: '11:00',
        days_before_arrival: 0,
        scopes: [],
        floor: 1,
        is_multi_unit: false,
      },
      listingObj: {
        ...this.formFields,
        ...(this.initialFields || {}),
      },
      priorities: ['low', 'med', 'high'],
      propertyTypes: [],
    }
  },
  async mounted() {
    this.initGoogleAutocomplete()

    if (this.listingObj.lat && this.listingObj.lng) {
      this.setInitialLocation(this.listingObj.lat, this.listingObj.lng)
    }
  },
  computed: {
    ...mapGetters('users', ['investors']),
    ...mapGetters(['otaTypes']),
  },
  methods: {
    close() {
      this.$store.commit('hideModal')
    },
    async createListing() {
      if (this.$refs.createListing.validate()) {
        this.saveDisabled = true
        await this.$store.dispatch('listings/createListing', this.listingObj)
        this.onSuccess && this.onSuccess()
        this.close()
      }
    },
    typeChanged() {
      this.listingObj.units = []
    },
    async setInitialLocation(lat, lng) {
      const geocoder = new window.google.maps.Geocoder()
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.updateLocationFields(results[0])
          }
        }
      })
    },
    async initGoogleAutocomplete() {
      const googlePlaces = get(window, 'google.maps.places', null)
      if (isNil(googlePlaces)) await loadGmaps()

      const inputElem = document.getElementById('autocomplete')
      this.autocomplete = new window.google.maps.places.Autocomplete(inputElem)
      this.autocomplete.addListener(
        'place_changed',
        this.onAutocomplete.bind(this)
      )
    },
    updateLocationFields(place) {
      const streetNumber = this.parsePlaceAttribute('street_number', place)
      const street = this.parsePlaceAttribute('route', place)
      if (street.includes(streetNumber)) {
        this.listingObj.street = street
      } else {
        this.listingObj.street = `${street} ${streetNumber}`
      }
      this.listingObj.zip_code = this.parsePlaceAttribute('postal_code', place)
      this.listingObj.country = this.parsePlaceAttribute('country', place)
      this.listingObj.city = this.parsePlaceAttribute('locality', place)
      this.listingObj.address = place.formatted_address
      this.listingObj.lat = place.geometry.location.lat()
      this.listingObj.lng = place.geometry.location.lng()
    },
    onAutocomplete() {
      const place = this.autocomplete.getPlace()
      if (isEmpty(place.address_components)) return false
      this.updateLocationFields(place)
    },

    parsePlaceAttribute(attribute, place) {
      const addressComponent =
        place.address_components.find(
          component => component.types[0] === attribute
        ) || {}
      if (isEmpty(addressComponent) || isNil(addressComponent.long_name)) {
        return ''
      }

      return addressComponent.long_name
    },
  },
}
</script>
