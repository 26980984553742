<template>
  <v-dialog v-model="dialog" max-width="560px">
    <template #activator="{ on }">
      <v-btn color="error" :block="buttonBlock" x-small v-on="on">
        {{ type }}</v-btn
      >
    </template>
    <v-card>
      <v-form ref="refundForm" @submit="submit">
        <v-card-title class="text-h5 text-capitalize grey lighten-2">
          {{ type }}
        </v-card-title>
        <v-card-text>
          <div v-if="type === 'refund'" class="text-body-1 py-3">
            Refunds/Cancel take 4-6 days to appear on a customer's statement
          </div>
          <div v-if="isRefundOrCapture">Maximum: {{ total }}</div>
          <v-text-field
            v-if="isRefundOrCapture"
            v-model="amount"
            dense
            outlined
            class="mt-3"
            :rules="[required, min(0), max(total)]"
            type="number"
            label="Amount"
          />
          <v-select
            v-if="isRefundOrCancel && resortsZone"
            v-model="reason"
            outlined
            class="mt-3"
            :hint="
              shouldCreateExpense
                ? '* You are about to create expense on that listing'
                : null
            "
            :persistent-hint="true"
            :items="reasons"
            :rules="[required]"
            label="Reason"
          />
          <div v-if="shouldCreateExpense" class="mt-2">
            <v-textarea
              v-model="note"
              placeholder="Explain the refund reason..."
              dense
              outlined
              :rules="[required]"
            />
          </div>
          <div v-if="error" class="error--text">{{ error }}</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn :disabled="loading" outlined color="primary" @click="onClose"
            >Cancel</v-btn
          >
          <v-btn
            :disabled="loading"
            :loading="loading"
            type="submit"
            color="primary"
            >Apply</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import formRules from '../mixins/form-rules-mixin'
import axios from '@/axios/config'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'RefundDialog',
  mixins: [formRules],
  props: ['type', 'total', 'intentId', 'metadata', 'buttonBlock'],
  data() {
    return {
      dialog: false,
      note: '',
      reason: '',
      loading: false,
      error: '',
      amount: this.total,
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    isRefundOrCancel() {
      return this.type === 'refund' || this.type === 'cancel'
    },
    resortsZone() {
      return !!this.currentUser.zone && this.currentUser.zone.name === 'Resorts'
    },
    isRefund() {
      return this.type === 'refund'
    },
    isRefundOrCapture() {
      return this.type === 'capture' || this.type === 'refund'
    },
    reasons() {
      if (this.type === 'cancel') {
        return [
          {
            text: 'Duplicate',
            value: 'duplicate',
          },
          {
            text: 'Fraudulent',
            value: 'fraudulent',
          },
          {
            text: 'Requested by customer',
            value: 'requested_by_customer',
          },
          {
            text: 'Abandoned',
            value: 'abandoned',
          },
        ]
      }
      return [
        {
          text: 'Guest canceled',
          value: 'canceled',
        },
        {
          text: 'Compensation for bad stay',
          value: 'compensation',
        },
        {
          text: 'Pricing error',
          value: 'pricing',
        },
      ]
    },
    shouldCreateExpense() {
      return this.type === 'refund' && this.reason === 'compensation'
    },
  },
  methods: {
    async submit(e) {
      e.preventDefault()

      if (this.$refs.refundForm.validate()) {
        try {
          this.loading = true
          const payload = {
            id: this.intentId,
            expense_create: this.shouldCreateExpense,
            note: this.note,
          }
          if (this.isRefundOrCapture) {
            payload.amount = (this.amount * 100).toFixed(0)
            payload.metadata = this.metadata || {}
          }
          await axios.post(`/api/payment/${this.type}`, payload)
          this.onSuccess()
        } catch ({ response }) {
          this.loading = false
          this.error = response.data.error
        }
      }
    },
    onSuccess() {
      this.onClose()
      this.$emit('success')
    },

    onClose() {
      this.dialog = false
      this.loading = false
      this.error = ''
      this.$refs.refundForm.reset()
    },
  },
}
</script>

<style scoped></style>
