var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{attrs:{"color":_vm.currentAgent ? 'grey lighten-3' : 'transparent'}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"color":"primary","text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.currentAgent || `Online agents (${_vm.activeCommUsers.length})`)+" ")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentAgent),expression:"currentAgent"}],attrs:{"color":"secondary","text":""},on:{"click":_vm.agentClear}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)]}}])},[(_vm.chatStats)?_c('v-list',_vm._l((_vm.activeCommUsers),function(agent){return _c('v-list-item',{key:agent.id,attrs:{"disabled":!_vm.chatStats.tickets_by_person[agent.id]}},[_c('v-list-item-title',[_c('div',{staticClass:"d-flex align-center"},[_c('user-avatar',{staticClass:"mr-4",attrs:{"name":`${agent.first_name} ${agent.last_name}`,"size":30,"image":agent.picture}}),_vm._v(" "+_vm._s(`${agent.first_name} ${agent.last_name}`)+" "),_c('v-spacer'),_c('v-btn',{staticClass:"text-caption ml-4",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.agentPick({
                  id: agent.id,
                  name: `${agent.first_name} ${agent.last_name} - Active`,
                })}}},[_c('span',{staticClass:"success--text font-weight-medium"},[_vm._v(" "+_vm._s(_vm.chatStats.tickets_by_person[agent.id] - _vm.chatStats.resolved_tickets[agent.id] || 0)+" ")]),_c('span',{staticClass:"ml-1"},[_vm._v("Active")])]),_c('v-btn',{staticClass:"text-caption ml-2",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.agentPick({
                  id: agent.id,
                  name: `${agent.first_name} ${agent.last_name} - Done`,
                  is_done: true,
                })}}},[_c('span',{staticClass:"warning--text font-weight-medium"},[_vm._v(" "+_vm._s(_vm.chatStats.resolved_tickets[agent.id] || 0)+" ")]),_c('span',{staticClass:"ml-1"},[_vm._v("Done")])])],1)])],1)}),1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }